import React, { useState } from 'react';
import ReactJsonView from '@microlink/react-json-view';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { darken } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { PreparationWaitDialog } from './PreparationWaitDialog';
import { usePaperworkPreparationPatches } from './usePaperworkPreparationPatches';

// import docxUrl from './modulo-m-v00000.docx';

async function loadTemplate(url: string) {
  return new Promise<PizZip>((resolve, reject) => {
    PizZipUtils.getBinaryContent(url, (error, content) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(new PizZip(content));
    });
  });
}

export function PreparationComponent() {
  const { paperwork } = usePaperwork();
  const patches = usePaperworkPreparationPatches();
  // const { data: module } = useReadTemplateQuery({ module: 'modulo-m-v00000' });
  const [helperDialogOpen, setHelperDialogOpen] = useState(false);

  // FIXME:
  // eslint-disable-next-line no-console
  console.log('### paperworkPreparationPatches', patches);

  async function handleRun() {
    if (!paperwork) {
      return;
    }

    const template = await loadTemplate(`/preparation/modules/${paperwork.paperworkTemplate.moduleName}.docx`);

    const doc = new Docxtemplater(template, {
      paragraphLoop: true,
      linebreaks: true,
    });
    doc.render(patches);
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }); // Output the document using Data-URI
    saveAs(out, `paperwork-${paperwork.id}.docx`);
  }

  return (
    <Stack direction="row" gap={2}>
      <PreparationWaitDialog prepareWordDocument={handleRun} />
      <Button
        onClick={() => setHelperDialogOpen(true)}
        color="inherit"
        sx={{
          color: '#fff',
          background: (theme) => {
            const v1 = darken(theme.palette.warning.main, 0.1);
            const v2 = darken(theme.palette.warning.dark, 0.2);
            return `repeating-linear-gradient(-45deg,${v1},${v1} 10px,${v2} 10px,${v2} 20px)`;
          },
        }}
      >
        {'Helper'}
      </Button>
      <Dialog onClose={() => setHelperDialogOpen(false)} open={helperDialogOpen} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" onClick={() => setHelperDialogOpen(false)} aria-label="close">
              {'X'}
            </IconButton>
            <div>Preparation helper</div>
          </Toolbar>
        </AppBar>
        <Typography component="div" fontSize={12}>
          <ReactJsonView src={patches} collapsed={1} enableClipboard={false} displayDataTypes={false} />
        </Typography>
      </Dialog>
    </Stack>
  );
}
