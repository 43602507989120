import { useMemo } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import { SERNI_LIST_UPDATE_STATUS, SerniListUpdateReview } from '../../entities/SerniListUpdate';
import { UserRole } from '../../entities/User';
import { useSerniListUpdate } from '../../hooks/useSerniListUpdate/useSerniListUpdate';
import {
  useReadSerniListUpdateCompletedPagesQuery,
  useReadSerniListUpdateReviewsQuery,
} from '../../services/serniListUpdate';

type UseSerniListUpdateReviewsReturnType = {
  showReviews: boolean;
  showEditReviews: boolean;
  reviews: SerniListUpdateReview[];
  allDataReviewed: boolean;
  hasRejectedReviews: boolean;
  attachmentsRejectedReviews: SerniListUpdateReview[];
  equipmentsRejectedReviews: SerniListUpdateReview[];
};

export function useSerniListUpdateReviews(): UseSerniListUpdateReviewsReturnType {
  const { serniListUpdate } = useSerniListUpdate();
  const { hasRole, isAdmin } = useAuth();

  const { data: reviews } = useReadSerniListUpdateReviewsQuery(
    { serniListUpdateId: serniListUpdate?.id ?? -1 },
    { skip: !serniListUpdate }
  );
  const readPaperworkCompletedPagesQuery = useReadSerniListUpdateCompletedPagesQuery(
    {
      serniListUpdateId: serniListUpdate?.id ?? -1,
    },
    { skip: !serniListUpdate }
  );

  return useMemo(() => {
    let rejectedReviewsCount = 0;
    const attachmentsRejectedReviews: SerniListUpdateReview[] = [];
    const equipmentsRejectedReviews: SerniListUpdateReview[] = [];

    if (reviews) {
      for (const review of reviews) {
        if (!review.review.isApproved) {
          rejectedReviewsCount = rejectedReviewsCount + 1;

          if (review.attachmentId !== undefined) {
            attachmentsRejectedReviews.push(review);
          }
          if (review.equipmentId !== undefined) {
            equipmentsRejectedReviews.push(review);
          }
        }
      }
    }

    const allDataReviewed = readPaperworkCompletedPagesQuery.data?.completedPages
      ? Object.values(readPaperworkCompletedPagesQuery.data.completedPages).every(
          (completedPage) => completedPage.reviewCompleted === true
        )
      : false;

    const paperworkReview: UseSerniListUpdateReviewsReturnType = {
      showReviews: false,
      showEditReviews: false,
      reviews: reviews ?? [],
      allDataReviewed: allDataReviewed,
      hasRejectedReviews: rejectedReviewsCount > 0,
      attachmentsRejectedReviews,
      equipmentsRejectedReviews,
    };

    if (!serniListUpdate) {
      return paperworkReview;
    }

    const statusId = serniListUpdate.status.id;

    if (statusId === SERNI_LIST_UPDATE_STATUS.DRAFT) {
      return paperworkReview;
    }

    if (!isAdmin && !hasRole(UserRole.GT)) {
      paperworkReview.showReviews = true;
      return paperworkReview;
    }

    paperworkReview.showReviews = true;
    paperworkReview.showEditReviews = true;

    return paperworkReview;
  }, [hasRole, isAdmin, serniListUpdate, readPaperworkCompletedPagesQuery, reviews]);
}
