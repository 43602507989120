import { PatchStakeholderListRequest, Stakeholder, StakeholderSchema } from '../entities/Stakeholder';
import { api, TAG_TYPES } from './baseApi';

const url = 'stakeholders';

export const stakeholderAPi = api.injectEndpoints({
  endpoints: (builder) => ({
    readStakeholderList: builder.query<Array<Stakeholder>, void>({
      query: () => ({ url }),
      transformResponse: (data) => StakeholderSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.STAKEHOLDER, id: 'LIST' }],
    }),
    patchStakeholderList: builder.mutation<{ id: number }, PatchStakeholderListRequest>({
      query: ({ paperworkId, ...body }) => ({ url: `/paperworks/${paperworkId}/stakeholders`, method: 'PATCH', body }),
      invalidatesTags: (_res, _err, req) => [
        { type: TAG_TYPES.STAKEHOLDER, id: 'LIST' },
        { type: TAG_TYPES.PAPERWORK, id: req.paperworkId },
        { type: TAG_TYPES.PAPERWORK_REVIEWS, id: req.paperworkId },
      ],
    }),
  }),
});

export const { useReadStakeholderListQuery, usePatchStakeholderListMutation } = stakeholderAPi;
