import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DownloadIcon } from '../../../../components/Icons';

export function PreparationWaitDialog(props: { prepareWordDocument: () => Promise<void> }) {
  const { prepareWordDocument } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
    await prepareWordDocument();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" startIcon={<DownloadIcon />} onClick={handleClickOpen}>
        {'Scarica richiesta preparata'}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{'Attendere'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: 4 }}>
            {'Scaricare il modulo di preparazione potrebbe richiedere alcuni secondi, attendere prego...'}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
