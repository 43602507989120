import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { EquipmentDraftSchema } from './Equipment';
import { PaperworkRoute } from './Paperwork';
import { ReviewSchema } from './Review';

export enum SERNI_LIST_UPDATE_STATUS {
  DRAFT = 110,
  WAITING = 120,
  TAKEN_CHARGE = 130,
  APPROVED = 240,
  CONDITIONAL_APPROVED = 220,
  RETURNED = 230,
  REJECTED = 210,
  PREPARED = 300,
  SIGNED = 400,
  SENT = 510,
  RELEASED = 530,
  SUSPENDED = 520,
  CONDITIONAL_RELEASED = 540,
  REFUSED_OR_CANCELLED = 550,
}

export const SerniListUpdateStatusSchema = z.object({
  id: z.nativeEnum(SERNI_LIST_UPDATE_STATUS),
  name: z.string(),
});

export const SerniListUpdateTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const SerniListUpdatePrioritySchema = z.object({
  id: z.number(),
  name: z.string(),
});

const SerniListUpdateHistoryEntrySchema = z.object({
  id: z.number(),
  status: SerniListUpdateStatusSchema,
  updatedAt: z.string().datetime(),
  username: z.string(),
  note: z.string().optional(),
});

export const SerniListUpdateSchema = z.object({
  id: z.number(),
  assignee: z.string(),
  status: SerniListUpdateStatusSchema,
  createdAt: z.string().datetime(),
  createdBy: z.string(),
  updatedAt: z.string().datetime(),
  priority: SerniListUpdatePrioritySchema,
  attachmentList: z.array(AttachmentSchema),
  equipmentList: z.array(EquipmentDraftSchema),
  historyList: SerniListUpdateHistoryEntrySchema.array().optional(), // FIXME:
});

export const AddSerniListUpdateSchema = z.object({
  assignee: z.string(),
  createdAt: z.date(),
  statusId: SerniListUpdateStatusSchema.shape.id,
  priorityId: SerniListUpdatePrioritySchema.shape.id,
});

const UpdateSerniListUpdateListPayloadSchema = z.object({
  id: SerniListUpdateSchema.shape.id,
  statusId: AddSerniListUpdateSchema.shape.statusId,
  priorityId: AddSerniListUpdateSchema.shape.priorityId,
  attachmentList: SerniListUpdateSchema.shape.attachmentList,
  equipmentList: SerniListUpdateSchema.shape.equipmentList,
});

const AddSerniListUpdateRequestSchema = z.object({
  priorityId: SerniListUpdatePrioritySchema.shape.id,
  assignee: AddSerniListUpdateSchema.shape.assignee,
  statusId: AddSerniListUpdateSchema.shape.statusId,
});

const DataEntryStatusSchema = z.object({
  dataEntryCompleted: z.boolean(),
  reviewCompleted: z.boolean(),
});

export const SerniListUpdateCompletedPagesSchema = z.object({
  completedPages: z
    .object({
      dataEntry: DataEntryStatusSchema.partial().optional(),
    })
    .partial(),
  id: z.number(),
});

export const SerniListUpdateReviewSchema = z.object({
  attachmentId: AttachmentSchema.shape.id.optional(),
  equipmentId: z.string().optional(),
  serniListId: SerniListUpdateSchema.shape.id,
  review: ReviewSchema,
});

const SerniListUpdateReviewUpdateSchema = z.object({
  ...SerniListUpdateReviewSchema.shape,
  review: z.object({
    comment: ReviewSchema.shape.comment,
    isApproved: ReviewSchema.shape.isApproved,
  }),
});

export type SerniListUpdateStatus = z.infer<typeof SerniListUpdateStatusSchema>;
export type AddSerniListUpdateRequest = z.infer<typeof AddSerniListUpdateRequestSchema>;
export type AddSerniListUpdate = z.infer<typeof AddSerniListUpdateSchema>;
export type SerniListUpdatePriority = z.infer<typeof SerniListUpdatePrioritySchema>;
export type SerniListUpdateType = z.infer<typeof SerniListUpdateTypeSchema>;
export type SerniListUpdateHistoryEntry = z.infer<typeof SerniListUpdateHistoryEntrySchema>;
export type SerniListUpdate = z.infer<typeof SerniListUpdateSchema>;
export type SerniListUpdateCompletedPages = z.infer<typeof SerniListUpdateCompletedPagesSchema>;
export type UpdateSerniListUpdateListPayload = z.infer<typeof UpdateSerniListUpdateListPayloadSchema>;
export type SerniListUpdateReview = z.infer<typeof SerniListUpdateReviewSchema>;
export type SerniListUpdateReviewUpdate = z.infer<typeof SerniListUpdateReviewUpdateSchema>;

/**
 * Object describing a single branch of the serniListUpdate routing
 */
export type SerniListUpdateRoute = Omit<
  PaperworkRoute,
  'context' | 'enabled' | 'active' | 'pages' | 'sections' | 'subsections' | 'icon'
> & {
  pages?: Record<string, SerniListUpdateRoute>;
  sections?: Record<string, SerniListUpdateRoute>;
  icon?: React.ReactNode;
};
