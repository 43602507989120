import { createListenerMiddleware, ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import debounce from 'lodash.debounce';
import { Paperwork, PAPERWORK_FIELD_DATATYPE, PAPERWORK_TYPE } from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import {
  AttachmentsSections,
  DataEntryPages,
  EndUsersSubsections,
  MaterialsSections,
  OrderSubsections,
  OtherAttachmentsSubsections,
  PaperworkPhases,
  StakeholdersSections,
} from '../../hooks/usePaperwork/paperworkNavigation';
import { paperworksApi } from '../../services/paperwork';
import { paperworkReviewApi } from '../../services/paperworkReview';
import { selectPaperworkNavigation } from '../paperworkNavigationSlice';
import { store } from '../store';

export const paperworkCompletionMiddleware = createListenerMiddleware();

async function checkCompletion(
  paperworkId: Paperwork['id'],
  state: ReturnType<typeof store.getState>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>
) {
  const { data: paperwork } = paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state);
  const { data: templates } = paperworksApi.endpoints.readPaperworkTemplatesList.select()(state);
  const { data: values } = paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state);
  const { routes } = selectPaperworkNavigation(state);

  let materialsCompleted = true;
  let stakeholdersCompleted = true;
  let attachmentsCompleted = true;
  let contractualTermsCompleted = true;
  let extensionCompleted = true;
  let closureCompleted = true;

  const paperworkTemplate =
    templates && paperwork && templates.find((template) => template.id === paperwork.paperworkTemplate.id);
  if (paperwork && templates && values && paperworkTemplate) {
    // const materialContexts =
    //   paperworkTemplate?.paperworkContextList.filter(
    //     (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Materials
    //   ) ?? [];
    // debugger;
    // const stakeholderContexts =
    //   paperworkTemplate?.paperworkContextList.filter(
    //     (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Stakeholders
    //   ) ?? [];

    // ##### Materials - Selection #######################################################################################################
    const selectMaterialsSections = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections;
    const selectMaterialsEnabled =
      selectMaterialsSections[MaterialsSections.SelectPartNumbers].enabled ||
      selectMaterialsSections[MaterialsSections.SelectPartNumbersWithoutEquipment].enabled ||
      selectMaterialsSections[MaterialsSections.SelectPartNumbersWithoutPn].enabled;

    if (selectMaterialsEnabled) {
      if (!paperwork.materialList || paperwork.materialList?.length === 0) {
        materialsCompleted = false;
      }
    }
    // ##### Materials #######################################################################################################

    // ##### Materials - Data entry ##########################################################################################
    const dataEntryMaterialsSection =
      routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[MaterialsSections.MaterialsData];

    if (dataEntryMaterialsSection.enabled) {
      const requiredMaterialFields =
        dataEntryMaterialsSection.context?.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredMaterialFields) {
        for (const material of paperwork.materialList ?? []) {
          const value = values?.find(
            (value) => value.materialId === material.id && value.fieldDatatype === requiredMaterialField.datatype
          );
          if (!value) {
            materialsCompleted = false;
          }
        }
      }
    }
    // ##### Materials - Data entry ##########################################################################################

    // ##### Stakeholders - ShipTo ###########################################################################################
    const shipToRoute =
      routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[StakeholdersSections.ShipTo];

    if (shipToRoute.enabled) {
      const shipToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
      );

      if (!shipToStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - ShipTo ###########################################################################################

    // ##### Stakeholders - OrderFrom ########################################################################################
    const orderFromRoute =
      routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[StakeholdersSections.OrderFrom];

    if (orderFromRoute.enabled) {
      const orderFromStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.ORDER_FROM
      );

      if (!orderFromStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - OrderFrom ########################################################################################

    // ##### Stakeholders - BillTo ###########################################################################################
    const billToFromRoute =
      routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[StakeholdersSections.BillTo];

    if (billToFromRoute.enabled) {
      const billToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.BILL_TO
      );

      if (!billToStakeholder) {
        stakeholdersCompleted = false;
      }
    }
    // ##### Stakeholders - BillTo ###########################################################################################

    // ##### Stakeholders - EndUser ##########################################################################################
    const endUserRoute =
      routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[StakeholdersSections.EndUser];
    const endUserContext = endUserRoute.context;
    const endUserMatchesValue = values.find(
      (value) => value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
    );

    if (endUserContext) {
      const endUseroStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.BILL_TO
      );

      if (!endUserMatchesValue) {
        stakeholdersCompleted = false;
      } else if (endUserMatchesValue.value === 'false' && !endUseroStakeholder) {
        stakeholdersCompleted = false;
      }
    }

    // ##### Stakeholders - EndUser ##########################################################################################

    // ##### Attachments #####################################################################################################
    const attachmentsPage = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments];

    const attachmentsSections = [
      attachmentsPage.sections[AttachmentsSections.Euc],
      attachmentsPage.sections[AttachmentsSections.Eus],
      attachmentsPage.sections[AttachmentsSections.Framework],
      attachmentsPage.sections[AttachmentsSections.Nda],
      attachmentsPage.sections[AttachmentsSections.VisuraCamerale],
      ...Object.values(attachmentsPage.sections[AttachmentsSections.Order].subsections),
      ...Object.values(attachmentsPage.sections[AttachmentsSections.OtherAttachments].subsections),
    ].filter((route) => route.enabled === true);

    // const attachmentsSections =
    //   paperworkTemplate?.paperworkContextList.filter((context) => context.page === DataEntryPages.Attachments) ?? [];
    let eusSection = attachmentsPage.sections[AttachmentsSections.Eus];

    if (attachmentsSections.length > 0) {
      if (!paperwork.paperworkAttachmentList) {
        attachmentsCompleted = false;
      } else {
        for (const attachmentsSection of attachmentsSections) {
          // Exclude EUS, it is a bit more complicate check
          if (attachmentsSection.path === AttachmentsSections.Eus) {
            eusSection = attachmentsSection;
            continue;
          }

          // Matrice #8: Exclude "Other attachments" / "Other Attachments" subsection (is optional)
          if (attachmentsSection.path === OtherAttachmentsSubsections.OtherAttachments) {
            continue;
          }

          // Visura camerale:	"obbligatorio (per tutte - LGT / LGP / AGT3 / AGT5) / facoltativo per LGP"
          if (attachmentsSection.path === AttachmentsSections.VisuraCamerale) {
            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Agt5) {
              continue;
            }

            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Lgp) {
              continue;
            }
          }

          if (paperwork.paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.IndividualeTangibile) {
            // Matrice 8
            // L. 185 - Licenza individuale - Temporanea Esportazione e successiva reimportazione
            // IIC, Certificato di ditta abilitata, Copia di una licenza valida di esportazione: alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 3
            if (
              attachmentsSection.path === OtherAttachmentsSubsections.Iic ||
              attachmentsSection.path === OtherAttachmentsSubsections.QualifiedCompanyCertificate ||
              attachmentsSection.path === OtherAttachmentsSubsections.CopyOfAValidExportLicence
            ) {
              const otherAttachmentsSubsections =
                attachmentsPage.sections[AttachmentsSections.OtherAttachments].subsections;

              const alternativeContexts = [
                otherAttachmentsSubsections[OtherAttachmentsSubsections.Iic].context?.id,
                otherAttachmentsSubsections[OtherAttachmentsSubsections.QualifiedCompanyCertificate].context?.id,
                otherAttachmentsSubsections[OtherAttachmentsSubsections.CopyOfAValidExportLicence].context?.id,
              ].filter((contextId) => Boolean(contextId));

              const sectionAttachment = paperwork.paperworkAttachmentList.find((attachment) =>
                alternativeContexts.includes(attachment?.savedInContextId)
              );
              if (!sectionAttachment) {
                attachmentsCompleted = false;
              }

              continue;
            }
          }

          if (attachmentsSection.path === AttachmentsSections.OtherAttachments) {
            if (paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.Proroga) {
              continue;
            }
          }

          const sectionAttachment = paperwork.paperworkAttachmentList.find(
            (attachment) => attachment?.savedInContextId === attachmentsSection.context?.id
          );
          if (!sectionAttachment) {
            attachmentsCompleted = false;
          }
        }
      }
    }

    if (endUserMatchesValue && eusSection && eusSection.enabled) {
      if (endUserMatchesValue.value !== 'microtecnica') {
        const eusAttachment = paperwork.paperworkAttachmentList?.find(
          (attachment) => attachment.savedInContextId === eusSection.context?.id
        );
        if (!eusAttachment) {
          attachmentsCompleted = false;
        }
      }
    }

    // ##### Attachments ####################################################################################################

    // ##### Contractual terms ##############################################################################################
    const contractualTermsPage = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.ContractualTerms];

    if (contractualTermsPage.enabled) {
      const requiredCTFields = contractualTermsPage.context?.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredCTFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          contractualTermsCompleted = false;
        }
      }
    }
    // ##### Contractual terms #############################################################################################

    // ##### Extension ##############################################################################################
    const extensionPage = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Extension];

    if (extensionPage) {
      const requiredExtensionFields = extensionPage.context?.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredExtensionFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          extensionCompleted = false;
        }
      }
    }
    // ##### Extension #############################################################################################

    // ##### Closure ##############################################################################################
    const closurePage = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Closure];

    if (closurePage) {
      const requiredFields = closurePage.context?.fieldList?.filter((field) => field.isRequired) ?? [];

      for (const requiredMaterialField of requiredFields) {
        const value = values?.find((value) => value.fieldDatatype === requiredMaterialField.datatype);
        if (!value) {
          closureCompleted = false;
        }
      }
    }
    // ##### Closure #############################################################################################

    // ##### Reviews #######################################################################################################

    const { data: reviews } = await paperworkReviewApi.endpoints.readPaperworkReviews.select({ paperworkId })(state);

    const materials = paperwork.materialList ?? [];
    const stakeholders = paperwork.stakeholderList ?? [];
    const attachments = paperwork.paperworkAttachmentList ?? [];

    let materialsReviewCompleted = false;
    let additionalEconomicConditionsCompleted = false;
    let stakeholdersReviewCompleted = false;
    let eusSkipInputSectionCompleted = false;
    let attachmentsReviewCompleted = false;
    let contractualTermsReviewCompleted = false;
    let extensionReviewCompleted = false;
    let closureReviewCompleted = false;

    const contractualTermsContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.ContractualTerms
      );
    const extensionContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Extension
      );
    const closureContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) => context.phase === PaperworkPhases.DataEntry && context.page === DataEntryPages.Closure
      );
    const eusSkipInputContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Stakeholders &&
          context.section === StakeholdersSections.EndUser &&
          context.subsection === EndUsersSubsections.SkipStakeholdersInputPanel
      );
    const orderApprovalContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Attachments &&
          context.section === AttachmentsSections.Order &&
          context.subsection === OrderSubsections.OrderApproval
      );
    const additionalEconomicConditionsContext = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.find(
        (context) =>
          context.phase === PaperworkPhases.DataEntry &&
          context.page === DataEntryPages.Materials &&
          context.section === MaterialsSections.AdditionalEconomicConditions
      );

    const signingContextsIDs = templates
      .find((template) => template.id === paperwork.paperworkTemplate.id)
      ?.paperworkContextList.filter((context) => context.phase === PaperworkPhases.PreparationSigning)
      .map((context) => context.id);

    if (reviews) {
      // Check every material has a review
      materialsReviewCompleted = materials.every((material) =>
        reviews.find((review) => review.materialId === material.id)
      );

      // Check every stakeholder has a review
      stakeholdersReviewCompleted = stakeholders.every((stakeholder) => {
        return reviews.find((review) => review.stakeholderId === stakeholder.id);
      });

      // Check every attachment has a review
      attachmentsReviewCompleted = attachments.every((paperworkAttachment) => {
        // Skip checking reviews on order approvals (reviews are done on orders)
        if (paperworkAttachment.savedInContextId === orderApprovalContext?.id) {
          return true;
        }

        // Ignore attachments added in Preparation & Signing phase
        if (signingContextsIDs && signingContextsIDs?.includes(paperworkAttachment.savedInContextId)) {
          return true;
        }

        return reviews.find((review) => review.attachmentId === paperworkAttachment.attachment.id);
      });

      // eslint-disable-next-line no-console
      console.log(
        '### debug, attachmentsReviewCompleted, attachments, reviews',
        attachmentsReviewCompleted,
        attachments,
        reviews
      );

      // Either the AEC section is not enabled for this paperwork, or it is reviewed
      additionalEconomicConditionsCompleted =
        !additionalEconomicConditionsContext ||
        Boolean(reviews?.find((review) => review.contextId === additionalEconomicConditionsContext.id));

      // Either the EUS skip input section is not enabled for this paperwork, or it is reviewed
      eusSkipInputSectionCompleted =
        !eusSkipInputContext || Boolean(reviews?.find((review) => review.contextId === eusSkipInputContext.id));

      // Either the contractual terms section is not enabled for this paperwork, or it is reviewed
      contractualTermsReviewCompleted =
        !contractualTermsContext || Boolean(reviews?.find((review) => review.contextId === contractualTermsContext.id));

      // Either the extension section is not enabled for this paperwork, or it is reviewed
      extensionReviewCompleted =
        !extensionContext || Boolean(reviews?.find((review) => review.contextId === extensionContext.id));

      // Either the closure section is not enabled for this paperwork, or it is reviewed
      closureReviewCompleted =
        !closureContext || Boolean(reviews?.find((review) => review.contextId === closureContext.id));
    }

    // ##### Reviews #######################################################################################################

    listenerApi.dispatch(
      paperworksApi.endpoints.patchPaperworkCompletedPages.initiate({
        id: paperworkId,
        completedPages: {
          materials: {
            dataEntryCompleted: materialsCompleted,
            reviewCompleted: materialsReviewCompleted && additionalEconomicConditionsCompleted,
          },
          stakeholders: {
            dataEntryCompleted: stakeholdersCompleted,
            reviewCompleted: stakeholdersReviewCompleted && eusSkipInputSectionCompleted,
          },
          attachments: {
            dataEntryCompleted: attachmentsCompleted,
            reviewCompleted: attachmentsReviewCompleted,
          },
          'contractual-terms': {
            dataEntryCompleted: contractualTermsCompleted,
            reviewCompleted: contractualTermsReviewCompleted,
          },
          extension: {
            dataEntryCompleted: extensionCompleted,
            reviewCompleted: extensionReviewCompleted,
          },
          closure: {
            dataEntryCompleted: closureCompleted,
            reviewCompleted: closureReviewCompleted,
          },
        },
      })
    );
  }
}

// Middlewares
const debouncedCheckCompletion = debounce(checkCompletion, 1000, { leading: false, trailing: true });

/**
 * Update the completion & review  status of the whole paperwork each time:
 * - The paperwork has been read
 * - The paperwork values have been read
 * - The paperwork reviews have been read
 */
paperworkCompletionMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperworkValues.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state).isLoading) {
      return;
    }

    checkCompletion(paperworkId, state, listenerApi);
  },
});

paperworkCompletionMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state).isLoading) {
      return;
    }

    debouncedCheckCompletion(paperworkId, state, listenerApi);
  },
});

paperworkCompletionMiddleware.startListening({
  matcher: paperworkReviewApi.endpoints.readPaperworkReviews.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.meta.arg.originalArgs.paperworkId;

    if (paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state).isLoading) {
      return;
    }

    debouncedCheckCompletion(paperworkId, state, listenerApi);
  },
});
