import { z } from 'zod';
import { TAG_TYPES, publicApi } from './publicApi';

export const preparationApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    readFixtures: builder.query<Record<string, string>, { fixturesSet: 'microtecnica' }>({
      query: ({ fixturesSet }) => ({ method: 'GET', url: `/preparation/fixtures/${fixturesSet}.json` }),
      transformResponse: (data) => z.record(z.string()).parse(data),
      providesTags: () => [{ type: TAG_TYPES.FIXTURES, id: 'LIST' }],
    }),
    readTemplate: builder.query<Blob, { module: string }>({
      query: ({ module }) => ({
        method: 'GET',
        url: `/preparation/modules/${module}.docx`,
        responseHandler: async (response) => await response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const { useReadFixturesQuery, useReadTemplateQuery } = preparationApi;
