import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { CancelIcon, CheckIcon } from '../Icons';

// Like type: 'boolean' but does not show undefined / null values as false
export const booleanColumn: Omit<GridColDef<GridValidRowModel, boolean>, 'field'> = {
  type: 'boolean',
  renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, boolean>) => {
    return value === true ? <CheckIcon fontSize="small" /> : value === false ? <CancelIcon fontSize="small" /> : '';
  },
};
