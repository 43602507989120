import React, { createContext, useContext, useMemo } from 'react';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ChevronRightIcon } from '../../../../components/Icons';
import { PaperworkHistoryEntry, PaperworkReview } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { useReadPaperworkReviewsQuery } from '../../../../services/paperworkReview';
import { ReviewCommentHistoryEntry } from './ReviewCommentHistoryEntry';
import { StatusChangeHistoryEntry } from './StatusChangeHistoryEntry';

const PANEL_TITLE = 'Storico pratica';

type HistoryDrawerContext = {
  historyDrawerOpen: boolean;
  setHistoryDrawerOpen: (open: boolean) => void;
};

export const HistoryDrawerContext = createContext<HistoryDrawerContext>({
  historyDrawerOpen: false,
  setHistoryDrawerOpen: () => {
    // noop
  },
});

export function HistoryDrawerProvider(props: { children: React.ReactNode }) {
  const [historyDrawerOpen, setHistoryDrawerOpen] = React.useState(window.innerWidth >= 600);

  return (
    <HistoryDrawerContext.Provider value={{ historyDrawerOpen, setHistoryDrawerOpen }}>
      {props.children}
    </HistoryDrawerContext.Provider>
  );
}

function HistoryDrawerComponent() {
  const { paperwork } = usePaperwork();
  const { data: reviews } = useReadPaperworkReviewsQuery({ paperworkId: paperwork?.id ?? -1 });
  const { historyDrawerOpen, setHistoryDrawerOpen } = useContext(HistoryDrawerContext);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setHistoryDrawerOpen(open);
  };

  const historyEntries: Array<{
    timestamp: string;
    historyEntry?: PaperworkHistoryEntry;
    review?: PaperworkReview;
  }> = useMemo(() => {
    if (!reviews) {
      return [];
    }
    return [
      ...(paperwork?.historyList.map((historyEntry) => ({
        timestamp: historyEntry.updatedAt,
        historyEntry,
      })) ?? []),
      ...reviews
        .filter((review) => review.review && !review.review.isApproved)
        .map((review) => ({
          timestamp: review.review.updatedAt,
          review,
        })),
    ].sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf());
  }, [paperwork, reviews]);

  return (
    <Drawer
      anchor="right"
      open={historyDrawerOpen}
      onClose={toggleDrawer(false)}
      variant="persistent"
      sx={{
        '& .MuiDrawer-paper': {
          maxHeight: 'calc(100vh - 64px)',
          backgroundColor: 'rgba(210, 217, 222, 25%)',
          borderBottom: 0,
          borderRight: 0,
        },
      }}
    >
      <Stack direction="column" maxHeight="100%" width={420}>
        <Stack
          direction="row"
          alignItems="center"
          paddingY={2}
          paddingX={1}
          gap={1}
          height={62}
          sx={{
            backgroundColor: 'rgba(74, 103, 125, 1)',
            color: 'white',
          }}
        >
          <IconButton onClick={toggleDrawer(false)} size="small" sx={{ color: 'white' }}>
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="h6" sx={{ position: 'relative', top: '0.0625em' }}>
            {PANEL_TITLE}
          </Typography>
        </Stack>
        <Divider />
        <Timeline
          position="right"
          sx={{
            minHeight: 0,
            overflow: 'auto',
            paddingY: 2,
            paddingX: 1,
            padding: 0,
            paddingLeft: 1,
            margin: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {historyEntries.map((entry, index, history) => {
            const firstEntry = index === history.length - 1;
            const lastEntry = index === 0;

            if (entry.historyEntry) {
              return (
                <StatusChangeHistoryEntry
                  key={entry.historyEntry.id}
                  historyEntry={entry.historyEntry}
                  firstEntry={firstEntry}
                  lastEntry={lastEntry}
                />
              );
            }
            if (entry.review) {
              return (
                <ReviewCommentHistoryEntry
                  key={`${entry.review.contextId}__m_${entry.review.materialId}__s_${entry.review.stakeholderId}__a_${entry.review.attachmentId}`}
                  review={entry.review}
                  lastEntry={lastEntry}
                />
              );
            }
            return null;
          })}
        </Timeline>
      </Stack>
    </Drawer>
  );
}

export const HistoryDrawer = React.memo(HistoryDrawerComponent);
