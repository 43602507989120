import React, { useMemo } from 'react';

import { Link, useMatch } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { orange, pink } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled, Theme, CSSObject, alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Logo } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { AppSection } from '../../entities/AppSection';
import { UserRole } from '../../entities/User';
import { useSectionList } from '../../hooks';
import { RouteIcon } from '../../hooks/usePaperwork/paperworkRouteConfiguration';
import { useIsPaperworkRouteEnabled } from '../../hooks/usePaperwork/useIsPaperworkRouteEnabled';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../hooks/usePaperwork/usePaperworkNavigation';
import { useSerniListUpdate } from '../../hooks/useSerniListUpdate/useSerniListUpdate';
import { ArrowBottomLeftBoldBoxOutlineIcon, BugOutlineIcon, ViewDashboardOutlineIcon } from '../Icons';

const drawerWidth = 400;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 57,
  minWidth: 57,
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.palette.background.paper}`,
  fontSize: 8,
}));

function MenuListItem(props: {
  text: string;
  open: boolean;
  icon: React.ReactNode;
  to: string;
  route?: string;
  nested?: boolean;
  disabled?: boolean;
}) {
  const matching = useMatch(props.route ?? '-');

  return (
    <Tooltip title={props.text} placement="right" arrow>
      <ListItem
        key={props.text}
        disablePadding
        sx={{
          display: 'block',
          position: 'relative',
          backgroundColor: matching
            ? (theme) => (props.nested ? alpha(theme.palette.primary.main, 0.1) : theme.palette.primary.main)
            : (theme) => (props.nested ? alpha(theme.palette.text.primary, 0.05) : undefined),
          color: matching ? (props.nested ? undefined : '#fff') : undefined,
          borderRight: matching ? '4px solid' : undefined,
          borderColor: matching ? 'primary.main' : undefined,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '16px',
            width: props.nested ? '2px' : '0px',
            backgroundColor: (theme) =>
              matching ? theme.palette.primary.light : alpha(theme.palette.text.primary, 0.2),
            transform: props.open ? 'scaleX(100%)' : 'scaleX(0%)',
            transition: (theme) => theme.transitions.create('transform'),
          },
        }}
      >
        <ListItemButton
          component={Link}
          to={props.to}
          disabled={props.disabled}
          sx={{
            minHeight: 48,
            px: 2,
            paddingLeft: props.nested && props.open ? 4 : undefined,
            transition: (theme) =>
              theme.transitions.create('padding', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              marginRight: 3,
              color: 'inherit',
            }}
          >
            {props.icon}
          </ListItemIcon>
          <ListItemText primary={props.text} sx={{ opacity: props.open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}

function SidebarDivider(props: { children: string; open: boolean }) {
  return (
    <ListItem
      key={props.children}
      disablePadding
      sx={{
        display: 'block',
        position: 'relative',
        textAlign: 'center',
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.05),
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '16px',
          width: '2px',
          backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.2),
          transform: props.open ? 'scaleX(100%)' : 'scaleX(0%)',
          transition: (theme) => theme.transitions.create('transform'),
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 11.5,
          left: 0,
          right: 0,
          height: '1px',
          backgroundColor: (theme) => theme.palette.divider,
          opacity: props.open ? 0 : 1,
          transition: (theme) => theme.transitions.create('opacity'),
        },
      }}
    >
      <Typography
        variant="caption"
        textTransform="uppercase"
        sx={{
          minHeight: 48,
          px: 2,
          opacity: props.open ? 0.8 : 0,
          transition: (theme) =>
            theme.transitions.create(['opacity'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shorter,
            }),
        }}
      >
        {props.children}
      </Typography>
    </ListItem>
  );
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: theme.zIndex.appBar,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function AppDrawerComponent() {
  const { userSectionList, adminSectionList } = useSectionList();
  const [open, setOpen] = React.useState(false);

  const navigation = usePaperworkNavigation();

  const { paperwork } = usePaperwork();
  const { serniListUpdate } = useSerniListUpdate();
  const isPaperworkRouteEnabled = useIsPaperworkRouteEnabled();

  const [paperworksSection, serniListUpdateSection, otherUserSections] = useMemo(() => {
    let paperworksSection: AppSection | undefined;
    let serniListUpdateSection: AppSection | undefined;
    const otherUserSections: AppSection[] = [];
    for (const section of userSectionList) {
      if (section.path === '/paperworks') {
        paperworksSection = section;
      } else if (section.path === '/serni-list-updates') {
        serniListUpdateSection = section;
      } else {
        otherUserSections.push(section);
      }
    }
    return [paperworksSection, serniListUpdateSection, otherUserSections];
  }, [userSectionList]);

  const auth = useAuth();

  return (
    <Stack direction="column">
      <Box sx={{ minWidth: 57, height: '100%' }}></Box>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ position: 'absolute' }}
        PaperProps={{ variant: 'elevation', elevation: 4 }}
      >
        <Stack direction="column" height={1}>
          <MenuListItem text="ILCM - Italian Law Compliance Manager" icon={<Logo width={24} />} open={open} to="/" />
          <Divider />
          <List>
            {paperworksSection ? (
              <>
                <MenuListItem
                  key={paperworksSection.path}
                  to={paperworksSection.path}
                  icon={paperworksSection.icon}
                  text={paperworksSection.title}
                  open={open}
                  route={`/${paperworksSection.path}/*`}
                />
                {paperwork ? (
                  <>
                    <MenuListItem
                      key={'detail'}
                      nested
                      to={`/paperworks/${paperwork.id}`}
                      icon={<ViewDashboardOutlineIcon />}
                      text={'Dashboard pratica'}
                      open={open}
                      route={`/paperworks/:id/`}
                    />
                    {Object.values(navigation.routes)?.map(
                      (route) =>
                        route.absolutePath.length > 0 && (
                          <Stack key={route.absolutePath}>
                            <SidebarDivider open={open}>{route.title}</SidebarDivider>
                            {route.pages && route.active ? (
                              <>
                                {Object.values(route.pages)?.map((route) =>
                                  route.active ? (
                                    <MenuListItem
                                      key={route.absolutePath}
                                      nested
                                      to={`/paperworks/${paperwork.id}/${route.absolutePath}`}
                                      icon={<RouteIcon iconName={route.icon} />}
                                      text={route.title}
                                      open={open}
                                      route={route.absoluteMatcher}
                                      disabled={!isPaperworkRouteEnabled(route)}
                                    />
                                  ) : null
                                )}
                              </>
                            ) : (
                              <MenuListItem
                                key={route.absolutePath}
                                nested
                                to={`/paperworks/${paperwork.id}/${route.absolutePath}`}
                                icon={<RouteIcon iconName={route.icon} />}
                                text={route.title}
                                open={open}
                                route={route.absoluteMatcher}
                                disabled={!isPaperworkRouteEnabled(route)}
                              />
                            )}
                          </Stack>
                        )
                    ) ?? null}
                  </>
                ) : null}
              </>
            ) : null}
            {serniListUpdateSection ? (
              <>
                <MenuListItem
                  key={serniListUpdateSection.path}
                  to={serniListUpdateSection.path}
                  icon={serniListUpdateSection.icon}
                  text={serniListUpdateSection.title}
                  open={open}
                  route={`/${serniListUpdateSection.path}/*`}
                />
                {serniListUpdate ? (
                  <>
                    <MenuListItem
                      key={'detail'}
                      nested
                      to={`/serni-list-update/${serniListUpdate.id}`}
                      icon={<ViewDashboardOutlineIcon />}
                      text={'Dashboard pratica'}
                      open={open}
                      route={`/serni-list-update/:id/`}
                    />
                    {Object.values(navigation.routes)?.map((route) => (
                      <>
                        <SidebarDivider open={open}>{route.title}</SidebarDivider>
                        {route.pages && route.active ? (
                          <>
                            {Object.values(route.pages)?.map((route) =>
                              route.active ? (
                                <MenuListItem
                                  key={route.absolutePath}
                                  nested
                                  to={`/serni-list-update/${serniListUpdate.id}/${route.absolutePath}`}
                                  icon={route.icon}
                                  text={route.title}
                                  open={open}
                                  route={route.absoluteMatcher}
                                />
                              ) : null
                            )}
                          </>
                        ) : (
                          <MenuListItem
                            key={route.absolutePath}
                            nested
                            to={`/serni-list-update/${serniListUpdate.id}/${route.absolutePath}`}
                            icon={route.icon}
                            text={route.title}
                            open={open}
                            route={route.absoluteMatcher}
                          />
                        )}
                      </>
                    )) ?? null}
                  </>
                ) : null}
              </>
            ) : null}
            {otherUserSections.map((section) => (
              <>
                <MenuListItem
                  key={section.path}
                  to={section.path}
                  icon={section.icon}
                  text={section.title}
                  open={open}
                  route={`/${section.path}/*`}
                />
              </>
            ))}
            <Divider />
            {adminSectionList.length > 0 && (
              <>
                {adminSectionList.map((section) => (
                  <MenuListItem
                    key={section.path}
                    to={section.path}
                    icon={section.icon}
                    text={section.title}
                    open={open}
                  />
                ))}
              </>
            )}
            <MenuListItem
              key="templates-debug"
              to="/templates-debug"
              icon={<BugOutlineIcon />}
              text={'Template debug'}
              open={false}
            />
          </List>
          <Divider />
          <Box flex={1} />
          <List>
            {auth ? (
              <ListItem disablePadding sx={{ display: 'block', marginLeft: 1 }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    auth.hasRole(UserRole.GT) ? (
                      <SmallAvatar sx={{ bgcolor: pink[500] }}>GT</SmallAvatar>
                    ) : auth.isAdmin ? (
                      <SmallAvatar sx={{ bgcolor: orange[800] }}>AD</SmallAvatar>
                    ) : null
                  }
                >
                  <Avatar
                    sx={{ bgcolor: 'primary.dark' }}
                  >{`${auth.firstname?.[0] ?? ''}${auth.lastname?.[0] ?? ''}`}</Avatar>
                </Badge>
              </ListItem>
            ) : null}
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => setOpen((open) => !open)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ArrowBottomLeftBoldBoxOutlineIcon sx={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                </ListItemIcon>
                <ListItemText primary={'Riduci'} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>
    </Stack>
  );
}

export const AppDrawer = React.memo(AppDrawerComponent);
