import { Country } from '@top-solution/microtecnica-utils';
import { EnabledCountry, EnabledCountrySchema } from '../entities/Country';
import { api, TAG_TYPES } from './baseApi';

const url = 'countries';

export const ilcmCountriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCountryList: builder.query<EnabledCountry[], void>({
      query: () => ({ url }),
      transformResponse: (data) => EnabledCountrySchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.COUNTRY, id: 'LIST' }],
    }),
    enableCountry: builder.mutation<void, EnabledCountry>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.COUNTRY, id: 'LIST' }],
    }),
    updateEnabledCountry: builder.mutation<void, EnabledCountry>({
      query: (body) => ({ url: `${url}/${body.code}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.COUNTRY, id: 'LIST' }],
    }),
    disableCountry: builder.mutation<void, { id: Country['id'] }>({
      query: ({ id }) => ({ url: `${url}/${id}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.COUNTRY, id: 'LIST' }],
    }),
  }),
});

export const {
  useReadCountryListQuery,
  useUpdateEnabledCountryMutation,
  useEnableCountryMutation,
  useDisableCountryMutation,
} = ilcmCountriesApi;
