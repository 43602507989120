import React from 'react';
import Alert from '@mui/material/Alert';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DetailSection } from '../../../components/DetailSection';
import { usePaperworkReviews } from '../../../components/review/usePaperworkReviews';
import { PAPERWORK_STATUS } from '../../../entities/Paperwork';
import { UserRole } from '../../../entities/User';
import {
  PaperworkPhases,
  DataEntryPages,
  PreparationSigningPages,
  IssuingDeductionPages,
} from '../../../hooks/usePaperwork/paperworkNavigation';
import { useIsPaperworkRouteEnabled } from '../../../hooks/usePaperwork/useIsPaperworkRouteEnabled';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkCompletedPagesQuery } from '../../../services/paperwork';
import { WizardList } from './WizardList';
import { WizardListItem } from './WizardListItem';

function NavigationComponent() {
  const { paperwork } = usePaperwork();
  const { routes } = usePaperworkNavigation();
  const isPaperworkRouteEnabled = useIsPaperworkRouteEnabled();

  const { data: completedPages } = useReadPaperworkCompletedPagesQuery(
    { paperworkId: paperwork?.id ?? 1 },
    { skip: !paperwork }
  );
  const { hasRole } = useAuth();

  const configurationRoutes = routes[PaperworkPhases.Configuration];
  const dataEntryRoute = routes[PaperworkPhases.DataEntry];
  const preparationSigningRoute = routes[PaperworkPhases.PreparationSigning];
  const issuingDeductionRoute = routes[PaperworkPhases.IssuingDeduction];

  const {
    materialsRejectedReviews,
    stakeholdersRejectedReviews,
    attachmentsRejectedReviews,
    contractualTermsRejectedReviews,
    extensionRejectedReviews,
    closureRejectedReviews,
  } = usePaperworkReviews();

  const mode = paperwork && paperwork.status.id < PAPERWORK_STATUS.TAKEN_CHARGE ? 'DATA_ENTRY' : 'REVIEW';

  const disabled =
    !paperwork ||
    (hasRole(UserRole.USER) &&
      paperwork.status.id >= PAPERWORK_STATUS.WAITING &&
      paperwork.status.id !== PAPERWORK_STATUS.RETURNED);

  const attachmentsDisabled = !isPaperworkRouteEnabled(dataEntryRoute.pages[DataEntryPages.Attachments]) || disabled;

  return (
    <>
      <DetailSection sectionTitle={configurationRoutes.title} disabled={disabled}>
        <WizardList>
          <WizardListItem
            mode={mode}
            text={configurationRoutes.title}
            completed
            reviewCompleted={false}
            linkTo={routes[PaperworkPhases.Configuration].absolutePath}
            disabled={disabled}
          />
        </WizardList>
      </DetailSection>
      <DetailSection sectionTitle={dataEntryRoute.title} disabled={disabled}>
        {hasRole('user') ? (
          paperwork?.status.id === PAPERWORK_STATUS.APPROVED ? (
            <Alert color="success">La pratica è stata approvata</Alert>
          ) : paperwork?.status.id === PAPERWORK_STATUS.CONDITIONAL_APPROVED ? (
            <Alert color="warning">La pratica è stata approvata con riserva</Alert>
          ) : paperwork?.status.id === PAPERWORK_STATUS.REJECTED ? (
            <Alert color="error">La pratica è stata rifiutata</Alert>
          ) : paperwork?.status.id === PAPERWORK_STATUS.RETURNED ? (
            <Alert color="error">La pratica è stata rimandata al mittente</Alert>
          ) : null
        ) : null}

        <WizardList>
          {dataEntryRoute.pages[DataEntryPages.Materials].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.Materials].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.Materials].absolutePath}
              commentCount={materialsRejectedReviews.length}
              completed={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Materials]?.dataEntryCompleted ?? false
                  : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Materials]?.reviewCompleted ?? false
                  : false
              }
              disabled={!isPaperworkRouteEnabled(dataEntryRoute.pages[DataEntryPages.Materials]) || disabled}
            />
          ) : null}
          {dataEntryRoute.pages[DataEntryPages.Stakeholders].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.Stakeholders].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.Stakeholders].absolutePath}
              commentCount={stakeholdersRejectedReviews.length}
              completed={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Stakeholders]?.dataEntryCompleted ?? false
                  : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Stakeholders]?.reviewCompleted ?? false
                  : false
              }
              disabled={!isPaperworkRouteEnabled(dataEntryRoute.pages[DataEntryPages.Stakeholders]) || disabled}
            />
          ) : null}
          {dataEntryRoute.pages[DataEntryPages.Extension].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.Extension].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.Extension].absolutePath}
              commentCount={extensionRejectedReviews.length}
              completed={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Extension]?.dataEntryCompleted ?? false
                  : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Extension]?.reviewCompleted ?? false
                  : false
              }
              disabled={!dataEntryRoute.pages[DataEntryPages.Extension].enabled || disabled}
            />
          ) : null}
          {dataEntryRoute.pages[DataEntryPages.Closure].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.Closure].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.Closure].absolutePath}
              commentCount={closureRejectedReviews.length}
              completed={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Closure]?.dataEntryCompleted ?? false
                  : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Closure]?.reviewCompleted ?? false
                  : false
              }
              disabled={!dataEntryRoute.pages[DataEntryPages.Closure].enabled || disabled}
            />
          ) : null}
          {dataEntryRoute.pages[DataEntryPages.Attachments].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.Attachments].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.Attachments].absolutePath}
              commentCount={attachmentsRejectedReviews.length}
              completed={
                attachmentsDisabled
                  ? false
                  : completedPages?.completedPages
                    ? completedPages.completedPages[DataEntryPages.Attachments]?.dataEntryCompleted ?? false
                    : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.Attachments]?.reviewCompleted ?? false
                  : false
              }
              disabled={attachmentsDisabled}
            />
          ) : null}
          {dataEntryRoute.pages[DataEntryPages.ContractualTerms].active ? (
            <WizardListItem
              mode={mode}
              text={dataEntryRoute.pages[DataEntryPages.ContractualTerms].title}
              linkTo={dataEntryRoute.pages[DataEntryPages.ContractualTerms].absolutePath}
              commentCount={contractualTermsRejectedReviews.length}
              completed={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.ContractualTerms]?.dataEntryCompleted ?? false
                  : false
              }
              reviewCompleted={
                completedPages?.completedPages
                  ? completedPages.completedPages[DataEntryPages.ContractualTerms]?.reviewCompleted ?? false
                  : false
              }
              disabled={!dataEntryRoute.pages[DataEntryPages.ContractualTerms].enabled || disabled}
            />
          ) : null}
        </WizardList>
      </DetailSection>
      {preparationSigningRoute.pages[PreparationSigningPages.Preparation].active ||
      preparationSigningRoute.pages[PreparationSigningPages.Signing].active ? (
        <DetailSection sectionTitle={preparationSigningRoute.title}>
          <WizardList>
            {preparationSigningRoute.pages[PreparationSigningPages.Preparation].active ? (
              <WizardListItem
                mode={mode}
                text={preparationSigningRoute.pages[PreparationSigningPages.Preparation].title}
                linkTo={preparationSigningRoute.pages[PreparationSigningPages.Preparation].absolutePath}
                commentCount={0}
                completed={false}
                reviewCompleted={false}
                disabled={!isPaperworkRouteEnabled(preparationSigningRoute.pages[PreparationSigningPages.Preparation])}
              />
            ) : null}
            {preparationSigningRoute.pages[PreparationSigningPages.Signing].active ? (
              <WizardListItem
                mode={mode}
                text={preparationSigningRoute.pages[PreparationSigningPages.Signing].title}
                linkTo={preparationSigningRoute.pages[PreparationSigningPages.Signing].absolutePath}
                commentCount={0}
                completed={false}
                reviewCompleted={false}
                disabled={!isPaperworkRouteEnabled(preparationSigningRoute.pages[PreparationSigningPages.Signing])}
              />
            ) : null}
          </WizardList>
        </DetailSection>
      ) : null}
      {issuingDeductionRoute.pages[IssuingDeductionPages.Issuing].active ||
      issuingDeductionRoute.pages[IssuingDeductionPages.Deduction].active ? (
        <DetailSection sectionTitle={issuingDeductionRoute.title}>
          <WizardList>
            {issuingDeductionRoute.pages[IssuingDeductionPages.Issuing].active ? (
              <WizardListItem
                mode={mode}
                text={issuingDeductionRoute.pages[IssuingDeductionPages.Issuing].title}
                linkTo={issuingDeductionRoute.pages[IssuingDeductionPages.Issuing].absolutePath}
                commentCount={0}
                completed={false}
                reviewCompleted={false}
                disabled={!isPaperworkRouteEnabled(issuingDeductionRoute.pages[IssuingDeductionPages.Issuing])}
              />
            ) : null}
            {issuingDeductionRoute.pages[IssuingDeductionPages.Deduction].active ? (
              <WizardListItem
                mode={mode}
                text={issuingDeductionRoute.pages[IssuingDeductionPages.Deduction].title}
                linkTo={issuingDeductionRoute.pages[IssuingDeductionPages.Deduction].absolutePath}
                commentCount={0}
                completed={false}
                reviewCompleted={false}
                disabled={!isPaperworkRouteEnabled(issuingDeductionRoute.pages[IssuingDeductionPages.Deduction])}
              />
            ) : null}
          </WizardList>
        </DetailSection>
      ) : null}
    </>
  );
}

export const Navigation = React.memo(NavigationComponent);
