import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { usePaperwork } from '../../hooks/usePaperwork/usePaperwork';

function PaperworkInfoPanelComponent(props: TypographyProps & { hideId?: boolean }) {
  const { paperwork } = usePaperwork();
  const { data } = useReadCountriesQuery();

  if (!paperwork || !paperwork.paperworkTemplate) {
    return null;
  }

  return (
    <Typography component="div" fontSize="0.85rem" {...props}>
      {[
        props.hideId ? '' : `Pratica #${paperwork?.id}`,
        paperwork.paperworkTemplate.paperworkType.name,
        paperwork.paperworkTemplate.movementType?.name,
        paperwork.paperworkTemplate.saleType?.name,
        data?.byISO[paperwork.country].name,
      ]
        .filter((s) => s && s?.length > 0)
        .join(' — ')}
    </Typography>
  );
}

export const PaperworkInfoPanel = React.memo(PaperworkInfoPanelComponent);
