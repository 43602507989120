import {
  AccountTieOutlineIcon,
  EmailFastOutlineIcon,
  FileCogOutlineIcon,
  FileDocumentOutlineIcon,
  FormatListCheckboxIcon,
  HandshakeOutlineIcon,
  NoteOffIcon,
  ScrewFlatTopIcon,
  TimelapseIcon,
} from '../../components/Icons';
import { PAPERWORK_STATUS, PAPERWORK_TYPE, PaperworkRouteConfiguration } from '../../entities/Paperwork';
import {
  PaperworksNavigationStructure,
  PaperworkPhases,
  DataEntryPages,
  MaterialsSections,
  StakeholdersSections,
  BillToSubsections,
  EndUsersSubsections,
  AttachmentsSections,
  PreparationSigningPages,
  IssuingDeductionPages,
  OtherAttachmentsSubsections,
  OrderSubsections,
  SigningSection,
} from './paperworkNavigation';

export enum RouteIconName {
  Configuration = 'Configuration',
  Materials = 'Materials',
  Stakeholders = 'Stakeholders',
  Attachments = 'Attachments',
  ContractualTerms = 'ContractualTerms',
  Extension = 'Extension',
  Closure = 'Closure',
  Signing = 'Signing',
  IssuingDeduction = 'IssuingDeduction',
}
/**
 * Routing configuration -> here goes anything about the configuration that can not be inferred
 * by the routing structure itself
 */
export const paperworkRoutesConfiguration: PaperworksNavigationStructure<PaperworkRouteConfiguration> = {
  [PaperworkPhases.Configuration]: {
    title: () => 'Configurazione',
    icon: RouteIconName.Configuration,
  },
  [PaperworkPhases.DataEntry]: {
    title: (paperwork) =>
      paperwork.status.id === PAPERWORK_STATUS.TAKEN_CHARGE ? 'Validazione dati' : 'Inserimento dati',
    icon: RouteIconName.Materials,
    pages: {
      [DataEntryPages.Materials]: {
        title: () => `Anagrafica materiali`,
        icon: RouteIconName.Materials,
        sections: {
          [MaterialsSections.BillingYears]: {
            title: () => 'Anni di fatturazione',
          },
          [MaterialsSections.SelectPartNumbers]: {
            title: () => 'Selezione P/N',
          },
          [MaterialsSections.SelectPartNumbersWithoutEquipment]: {
            title: () => 'Selezione P/N',
          },
          [MaterialsSections.SelectPartNumbersWithoutPn]: {
            title: () => 'Selezione Materiali',
          },
          [MaterialsSections.MaterialsData]: {
            title: () => 'Inserimento dati materiali',
          },
          [MaterialsSections.PaperworkTransaction]: {
            title: () => 'Transazione',
          },
          [MaterialsSections.TotalQuantity]: {
            title: () => 'Quantità Totale',
          },
          [MaterialsSections.MaterialKeepHyphen]: {
            title: () => 'Mantenere il tratto dei P/N nelle richieste di licenza',
          },
          [MaterialsSections.AdditionalEconomicConditions]: {
            title: () => 'Condizioni Economiche aggiuntive',
          },
        },
      },
      [DataEntryPages.Stakeholders]: {
        title: () => `Anagrafica parti coinvolte`,
        icon: RouteIconName.Stakeholders,
        sections: {
          [StakeholdersSections.ShipTo]: {
            title: () => 'Mittente/Destinatario (Ship to)',
            order: 0,
          },
          [StakeholdersSections.OrderFrom]: {
            title: () => "Ente che emette l'ordine",
            order: 1,
          },
          [StakeholdersSections.Buyer]: {
            title: () => 'Acquirente',
            order: 1,
          },
          [StakeholdersSections.BillTo]: {
            title: (paperwork) =>
              paperwork.paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.IntermediazioneFramework ||
              paperwork.paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.IntermediazioneOrdini
                ? 'Beneficiario pagamento (Fornitore)'
                : 'Intestatario/beneficiario pagamento',
            order: 2,
            subsections: {
              [BillToSubsections.SkipStakeholdersInputPanel]: {
                title: () => '',
              },
            },
          },
          [StakeholdersSections.EndUser]: {
            title: () => 'End User',
            order: 3,
            subsections: {
              [EndUsersSubsections.SkipStakeholdersInputPanel]: {
                title: () => '',
              },
            },
          },
        },
      },
      [DataEntryPages.Attachments]: {
        title: () => 'Documenti',
        icon: RouteIconName.Attachments,
        sections: {
          [AttachmentsSections.Order]: {
            title: () => 'Ordini',
            subsections: {
              [OrderSubsections.Order]: { title: () => 'Ordine' },
              [OrderSubsections.OrderApproval]: { title: () => "Accettazione dell'ordine" },
            },
          },
          [AttachmentsSections.Euc]: {
            title: () => 'EUC',
          },
          [AttachmentsSections.Eus]: {
            title: () => 'EUS',
          },
          [AttachmentsSections.Framework]: {
            title: () => 'Framework',
          },
          [AttachmentsSections.Nda]: {
            title: () => 'NDA',
          },
          [AttachmentsSections.VisuraCamerale]: {
            title: () => 'Visura camerale',
          },
          [AttachmentsSections.OtherAttachments]: {
            title: () => 'Altri documenti',
            subsections: {
              [OtherAttachmentsSubsections.LoiVendor]: { title: () => 'LOI del fornitore' },
              [OtherAttachmentsSubsections.LoiCustomer]: { title: () => 'LOI del cliente' },
              [OtherAttachmentsSubsections.OrderVendor]: { title: () => 'Ordine Fornitore' },
              [OtherAttachmentsSubsections.OrderCustomer]: { title: () => 'Ordine Cliente' },
              [OtherAttachmentsSubsections.VendorLicence]: { title: () => 'Licenza del fornitore' },
              [OtherAttachmentsSubsections.SupportingDocuments]: { title: () => 'Documenti a supporto della proroga' },
              [OtherAttachmentsSubsections.EveryLicenceAvailableBill]: {
                title: () => 'Elenco di tutte le fatture reperibili scaricate a fronte della licenza',
              },
              [OtherAttachmentsSubsections.EveryBillOrInvoice]: {
                title: () => 'Elenco delle fatture da incassare / pagare',
              },
              [OtherAttachmentsSubsections.CopyInvoices]: { title: () => 'Copia delle fatture da incassare' },
              [OtherAttachmentsSubsections.QualifiedCompanyCertificate]: {
                title: () => 'Certificato di ditta abilitata',
              },
              [OtherAttachmentsSubsections.CopyOfAValidExportLicence]: {
                title: () => 'Copia di una licenza valida di esportazione',
              },
              [OtherAttachmentsSubsections.Iic]: {
                title: () => 'IIC',
              },
              [OtherAttachmentsSubsections.OrderDetails]: {
                title: () => 'Eventuale file excel con dettaglio ordini',
              },
              [OtherAttachmentsSubsections.OtherAttachments]: {
                title: () => 'Altri documenti',
              },
            },
          },
        },
      },
      [DataEntryPages.ContractualTerms]: {
        title: () => 'Termini contrattuali',
        icon: RouteIconName.ContractualTerms,
      },
      [DataEntryPages.Extension]: {
        title: () => 'Proroga',
        icon: RouteIconName.Extension,
      },
      [DataEntryPages.Closure]: {
        title: () => 'Chiusura',
        icon: RouteIconName.Closure,
      },
    },
  },
  [PaperworkPhases.PreparationSigning]: {
    title: () => 'Preparazione e invio',
    pages: {
      [PreparationSigningPages.Preparation]: {
        title: () => 'Preparazione pratica',
        icon: RouteIconName.Signing,
      },
      [PreparationSigningPages.Signing]: {
        icon: RouteIconName.Signing,
        title: () => 'Firma e invio',
        sections: {
          [SigningSection.Attachments]: {
            title: () => 'Allegati email',
          },
        },
      },
    },
  },
  [PaperworkPhases.IssuingDeduction]: {
    title: () => 'Rilascio e scarico autorizzazione',
    icon: RouteIconName.IssuingDeduction,
    pages: {
      [IssuingDeductionPages.Issuing]: {
        title: () => 'Rilascio autorizzazione',
      },
      [IssuingDeductionPages.Deduction]: {
        title: () => 'Scarico',
      },
    },
  },
};

export function RouteIcon(props: { iconName: RouteIconName | undefined }) {
  switch (props.iconName) {
    case RouteIconName.Configuration:
      return <FileCogOutlineIcon />;
    case RouteIconName.Materials:
      return <ScrewFlatTopIcon />;
    case RouteIconName.Stakeholders:
      return <AccountTieOutlineIcon />;
    case RouteIconName.Attachments:
      return <FileDocumentOutlineIcon />;
    case RouteIconName.ContractualTerms:
      return <HandshakeOutlineIcon />;
    case RouteIconName.Extension:
      return <TimelapseIcon />;
    case RouteIconName.Closure:
      return <NoteOffIcon />;
    case RouteIconName.Signing:
      return <EmailFastOutlineIcon />;
    case RouteIconName.IssuingDeduction:
      return <FormatListCheckboxIcon />;
    default:
      return null;
  }
}
