import { useMemo } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';

export enum UNITY_OF_MEASURE {
  EA = 'ea',
  MAN_MONTH = 'uomo mese',
}

export function useMaterialUnityOfMeasureColumn(): GridSingleSelectColDef {
  const { byDataType } = usePaperworkFields();
  const valueOptions = Object.values(UNITY_OF_MEASURE);

  return useMemo(
    () => ({
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE]?.label,
      type: 'singleSelect',
      valueOptions,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE,
      width: 150,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE]?.value;
      },
    }),
    [byDataType, valueOptions]
  );
}
