import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { stringDateColumn } from '../../../../../components/DataGrid/stringDateColumn';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';

export function useMaterialOrderNumberColumn(): GridColDef {
  const { byDataType } = usePaperworkFields();

  return useMemo(
    () => ({
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]?.label,
      width: 100,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]?.value;
      },
    }),
    [byDataType]
  );
}

export function useMaterialOrderDateColumn(): GridColDef {
  const { byDataType } = usePaperworkFields();

  return useMemo(
    () => ({
      ...stringDateColumn,
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]?.label,
      width: 100,
      type: 'date',
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]?.value
          ? new Date(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]?.value)
          : new Date(0);
      },
    }),
    [byDataType]
  );
}

export function useMaterialOrderRowColumn(): GridColDef {
  const { byDataType } = usePaperworkFields();
  return useMemo(
    () => ({
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW]?.label,
      width: 100,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        return row[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW]?.value;
      },
    }),
    [byDataType]
  );
}
