import { createListenerMiddleware, ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import { countryApi } from '@top-solution/microtecnica-utils';
import { ilcmCountriesApi } from '../../services/countries';
import { CountryClassification, setCountriesClassification } from '../countriesClassificationSlice';
import { store } from '../store';

countries.registerLocale(it);

export const countriesClassificationMiddleware = createListenerMiddleware();

async function classifyCountries(
  state: ReturnType<typeof store.getState>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>
) {
  const { data: cdCountries } = await countryApi.endpoints.readCountries.select()(state);
  const { data: ilcmCountries } = await ilcmCountriesApi.endpoints.readCountryList.select()(state);

  if (!cdCountries || !ilcmCountries) {
    return;
  }

  const countriesClassification: Record<string, CountryClassification> = {};

  for (const ilcmCountry of ilcmCountries) {
    const cdCountry = cdCountries.byISO[ilcmCountry.code];
    if (!cdCountry) {
      continue;
    }

    const classification: CountryClassification = {
      country: {
        id: cdCountry.id,
        name: cdCountry.name,
        italianName: countries.getName(cdCountry.id, 'it') ?? cdCountry.name,
        geonomCode: cdCountry.geonomCode,
      },
      isEu: ilcmCountry.category === 'EU',
      isNato: ilcmCountry.category === 'NATO' || cdCountry.isNato,
      isExtraNato: ilcmCountry.category === 'NON-NATO' || (!cdCountry.isEU && !cdCountry.isNato),
    };

    countriesClassification[ilcmCountry.code] = classification;
  }

  if (!countriesClassification['IT']) {
    const itCdCountry = cdCountries.byISO['IT']!;
    countriesClassification['IT'] = {
      country: {
        id: 'IT',
        name: itCdCountry.name,
        italianName: countries.getName('IT', 'it') ?? 'Italia',
        geonomCode: itCdCountry.geonomCode,
      },
      isEu: true,
      isNato: true,
      isExtraNato: false,
    };
  }

  listenerApi.dispatch(setCountriesClassification({ countries: countriesClassification }));
}

countriesClassificationMiddleware.startListening({
  matcher: countryApi.endpoints.readCountries.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    classifyCountries(state, listenerApi);
  },
});

countriesClassificationMiddleware.startListening({
  matcher: ilcmCountriesApi.endpoints.readCountryList.matchFulfilled,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    classifyCountries(state, listenerApi);
  },
});
