import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { PaperworkContext, PaperworkRoute } from '../../entities/Paperwork';

import { useReadCountryListQuery } from '../../services/countries';
import { useReadPaperworkTemplatesListQuery } from '../../services/paperwork';
import {
  PaperworkNavigationSlice,
  selectPaperworkNavigation,
  setPaperworkId,
} from '../../store/paperworkNavigationSlice';
import { PaperworkPhases } from './paperworkNavigation';
import { usePaperwork } from './usePaperwork';

export function usePaperworkNavigation(): PaperworkNavigationSlice & {
  navigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'>;
  route: PaperworkRoute | undefined;
} {
  const { paperwork } = usePaperwork();
  const { data: templateList } = useReadPaperworkTemplatesListQuery();
  const { data: ilcmCountries } = useReadCountryListQuery();
  const { data: cdCountries } = useReadCountriesQuery();
  const { pathname } = useLocation();
  const paperworkNavigation = useSelector(selectPaperworkNavigation);

  const dispatch = useDispatch();
  useEffect(() => {
    if (paperwork && templateList && ilcmCountries && cdCountries) {
      // Start checking the routing when everything is loaded
      dispatch(setPaperworkId({ paperworkId: paperwork.id }));
    }
  }, [cdCountries, dispatch, ilcmCountries, paperwork, templateList]);

  const [currentNavigationContext, currentRoute] = useMemo(() => {
    // The NavigationContext of the specific route the browser is currently on
    let currentNavigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'> = {
      phase: PaperworkPhases.Configuration,
    };

    let currentRoute: PaperworkRoute = {
      path: '',
      absolutePath: '',
      title: '',
      matcher: '',
      absoluteMatcher: '',
      active: false,
      enabled: false,
    };

    // FIXME:
    if (paperworkNavigation.routes) {
      for (const [_, phase] of Object.entries(paperworkNavigation.routes)) {
        if (phase.pages) {
          for (const [_, page] of Object.entries(phase.pages)) {
            if (matchPath(page.absoluteMatcher, pathname)) {
              currentNavigationContext = { ...currentNavigationContext, ...page.context };
              currentRoute = page;
            }
            if (page.sections) {
              for (const [_, section] of Object.entries(page.sections)) {
                if (matchPath(section.absoluteMatcher, pathname)) {
                  currentNavigationContext = { ...currentNavigationContext, ...section.context };
                  currentRoute = section;
                }
                if (section.subsections) {
                  for (const [_, subsection] of Object.entries(section.subsections)) {
                    if (matchPath(subsection.absoluteMatcher, pathname)) {
                      currentNavigationContext = { ...currentNavigationContext, ...subsection.context };
                      currentRoute = subsection;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return [currentNavigationContext, currentRoute];
  }, [paperworkNavigation.routes, pathname]);

  return useMemo(
    () => ({
      ...paperworkNavigation,
      routes: paperworkNavigation.routes,
      /**
       * The NavigationContext related to the route the browser is currently on
       */
      navigationContext: currentNavigationContext,
      /**
       * The the route the browser is currently on
       */
      route: currentRoute as unknown as PaperworkRoute | undefined,
    }),
    [currentNavigationContext, currentRoute, paperworkNavigation]
  );
}
