import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { SerniListUpdateStatusChip } from '../../../components/DataGrid/useSerniListUpdateStatusColumn';
import { DetailSection } from '../../../components/DetailSection';
import { ChevronLeftIcon } from '../../../components/Icons';
import { PriorityInlineBadgeLabel } from '../../../components/InlineBadgeLabel/PriorityInlineBadgeLabel';
import { Layout } from '../../../components/Layout';
import { NavigationButton } from '../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../components/PageTitle';
import { UserName } from '../../../components/User';
import { SERNI_LIST_UPDATE_STATUS } from '../../../entities/SerniListUpdate';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { formatDateTime } from '../../../utils/dates';
import { DetailPanel, DetailPanelRow } from '../../Paperwork/PaperworkDetail/DetailPanel';
import { HistoryDrawer, HistoryDrawerContext, HistoryDrawerProvider } from './HistoryDrawer/HistoryDrawer';
import { Navigation } from './Navigation';
import { SerniListUpdatePrimaryActions } from './SerniListUpdatePrimaryActions';

function SerniListUpdateDetailPageComponent() {
  const { serniListUpdate } = useSerniListUpdate();

  const assignee = serniListUpdate?.assignee;

  const pageTitle = `Richiesta aggiornamento lista Serni #${serniListUpdate?.id}`;
  const { historyDrawerOpen, setHistoryDrawerOpen } = useContext(HistoryDrawerContext);

  return (
    <Layout
      title={pageTitle}
      header={
        <PageTitle
          pageTitle={pageTitle}
          rightItems={
            <Stack direction="row" gap={2}>
              {serniListUpdate ? (
                <Button startIcon={<ChevronLeftIcon />} onClick={() => setHistoryDrawerOpen(true)} color="secondary">
                  {'Storico pratica'}
                </Button>
              ) : null}
            </Stack>
          }
          sx={{ marginBottom: 0 }}
        />
      }
      maxWidth={false}
      sx={{ padding: 0, height: '100vh' }}
    >
      <Stack direction="column" gap={2} sx={{ minHeight: '100%', maxHeight: '100%' }}>
        <Stack
          direction="row"
          sx={{
            overflow: 'auto',
            height: '100%',
            marginRight: historyDrawerOpen ? `${420}px` : 0,
            transition: (theme) =>
              historyDrawerOpen
                ? theme.transitions.create(['marginRight', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  })
                : theme.transitions.create(['marginRight', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
          }}
        >
          <DetailSection sectionTitle="Informazioni pratica" paddingX={2} sx={{ flex: 1 }}>
            <Stack direction="row" alignItems="flex-start" flexWrap="wrap" gap={2}>
              <DetailPanel>
                <DetailPanelRow rowTitle="ID Pratica" rowValue={`#${serniListUpdate?.id}`} />
                <DetailPanelRow rowTitle="Data creazione" rowValue={formatDateTime(serniListUpdate?.createdAt)} />
                <DetailPanelRow rowTitle="Data ultima modifica" rowValue={formatDateTime(serniListUpdate?.updatedAt)} />
                <DetailPanelRow
                  rowTitle="Priorità"
                  rowValue={<PriorityInlineBadgeLabel priority={serniListUpdate?.priority} />}
                />
                {assignee ? (
                  <DetailPanelRow
                    rowTitle={
                      serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.DRAFT ||
                      serniListUpdate.status.id === SERNI_LIST_UPDATE_STATUS.RETURNED
                        ? 'Assegnata a'
                        : 'In carico a'
                    }
                    rowValue={<UserName username={assignee} />}
                  />
                ) : null}

                <DetailPanelRow
                  direction="row"
                  rowTitle="Stato"
                  rowValue={
                    serniListUpdate?.status ? <SerniListUpdateStatusChip status={serniListUpdate?.status.id} /> : '—'
                  }
                  sx={{ paddingTop: 1 }}
                />
              </DetailPanel>
            </Stack>
            <Navigation />
          </DetailSection>
          <HistoryDrawer />
        </Stack>
        <NavigationPanel
          leftActions={
            <>
              <NavigationButton component={Link} to={'/serni-list-updates'}>
                {`Torna alla lista pratiche`}
              </NavigationButton>
            </>
          }
          primaryActions={<SerniListUpdatePrimaryActions />}
        ></NavigationPanel>
      </Stack>
    </Layout>
  );
}

export const SerniListUpdateDetailPage = React.memo(function SerniListUpdateDetailPageWrapper() {
  return (
    <HistoryDrawerProvider>
      <SerniListUpdateDetailPageComponent />
    </HistoryDrawerProvider>
  );
});
