import { useMemo } from 'react';
import { z } from 'zod';
import { GridColDef } from '@mui/x-data-grid-premium';
import { booleanColumn } from '../../../../../components/DataGrid/booleanColumn';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';

export const FreeValueSchema = z.object({
  freeValue: z.boolean(),
  motivation: z.string(),
});

export function useMaterialFreeSaleColumn(): GridColDef {
  const { byDataType } = usePaperworkFields();

  return useMemo(
    () => ({
      ...booleanColumn,
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]?.label,
      width: 140,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const fieldValue = row[PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE]?.value;
        if (!fieldValue) {
          return undefined;
        }
        return fieldValue === 'true';
      },
    }),
    [byDataType]
  );
}
