import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Country } from '@top-solution/microtecnica-utils';
import { RootState } from './store';

export type CountryClassification = {
  country: Pick<Country, 'id' | 'name' | 'geonomCode'> & { italianName: string };
  isNato: boolean;
  isEu: boolean;
  isExtraNato: boolean;
};

type CountriesClassificationSlice = {
  countries: Record<string, CountryClassification>;
};

const initialState: CountriesClassificationSlice = { countries: {} };

export const countriesClassificationSlice = createSlice({
  name: 'paperworkNavigation',
  initialState,
  reducers: {
    setCountriesClassification: (
      state,
      action: PayloadAction<{ countries: Record<string, CountryClassification> }>
    ) => {
      state.countries = action.payload.countries;
    },
  },
});

export const { setCountriesClassification } = countriesClassificationSlice.actions;

export const selectCountriesClassification = (state: RootState) => state.countriesClassification ?? {};

export default countriesClassificationSlice.reducer;
