import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';
import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { currencyFormatter } from '../../../../../utils/numbers';
import { Currency } from '../../../../Currency/currency';

export function useMaterialUnitValueColumn(): GridColDef {
  const { data: currencies } = useReadCurrencyListQuery();
  const { byDataType } = usePaperworkFields();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );
  return useMemo(
    () => ({
      headerName: byDataType[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]?.label,
      type: 'number',
      width: 200,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]?.value);
        return Number.isNaN(v) ? null : v;
      },
      valueFormatter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        if (!currenciesById || !row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]?.value) return value;
        return `${currencyFormatter.format(value)} ${currenciesById!.get(Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]!.value))?.symbol}`;
      },
    }),
    [byDataType, currenciesById]
  );
}
