import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Paperwork, PaperworkRoute } from '../entities/Paperwork';
import {
  emptyPaperworkNavigation as emptyPaperworkNavigation,
  PaperworksNavigationStructure,
} from '../hooks/usePaperwork/paperworkNavigation';
import { RootState } from './store';

export type PaperworkNavigationSlice = {
  paperworkId: Paperwork['id'];
  /**
   * A PaperworksNavigationStructure-like object describing the routes of /paperwork
   */
  routes: PaperworksNavigationStructure<PaperworkRoute>;
  /**
   * The path of the Paperwork Details page
   */
  detailsPath: string;
  /**
   * Get a route in exchange for a context ID
   */
  routeByContextId: Record<number, PaperworkRoute>;
};

const initialState: PaperworkNavigationSlice = {
  paperworkId: -1,
  routes: emptyPaperworkNavigation,
  detailsPath: '/dashboard',
  routeByContextId: {},
};

export const paperworkNavigationSlice = createSlice({
  name: 'paperworkNavigation',
  initialState,
  reducers: {
    setPaperworkId: (state, action: PayloadAction<{ paperworkId: Paperwork['id'] }>) => {
      state.paperworkId = action.payload.paperworkId;
    },
    setPaperworkNavigation: (state, action: PayloadAction<PaperworkNavigationSlice>) => {
      state.routes = action.payload.routes;
      state.detailsPath = action.payload.detailsPath;
      state.routeByContextId = action.payload.routeByContextId;
    },
  },
});

export const { setPaperworkNavigation, setPaperworkId } = paperworkNavigationSlice.actions;

export const selectPaperworkNavigation = (state: RootState) => state.paperworkNavigation ?? {};

export default paperworkNavigationSlice.reducer;
