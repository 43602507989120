import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ClipboardTextIcon } from '../../../../components/Icons';
import { UserName } from '../../../../components/User/UserName';
import { SerniListUpdateReview } from '../../../../entities/SerniListUpdate';
import { useSerniListUpdate } from '../../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { DetailPanelRow } from '../../../Paperwork/PaperworkDetail/DetailPanel';
import { BaseHistoryEntry, BaseHistoryEntryProps } from './BaseHistoryEntry';

function ReviewCommentHistoryEntryComponent(
  props: Pick<BaseHistoryEntryProps, 'lastEntry'> & {
    review: SerniListUpdateReview;
  }
) {
  const { review, lastEntry, ...baseHistoryEntryProps } = props;

  const { serniListUpdate } = useSerniListUpdate();

  const reviewEquipment =
    props.review.equipmentId !== undefined
      ? serniListUpdate?.equipmentList?.find((equipment) => equipment.equipment === props.review.equipmentId)
      : undefined;
  const reviewAttachment =
    props.review.attachmentId !== undefined
      ? serniListUpdate?.attachmentList?.find((attachment) => attachment.id === props.review.attachmentId)
      : undefined;

  return (
    <BaseHistoryEntry
      {...baseHistoryEntryProps}
      timestamp={new Date(review.review.updatedAt)}
      lastEntry={lastEntry}
      firstEntry={false}
      IconComponent={ClipboardTextIcon}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="body1" fontWeight={500}>
          <UserName username={review.review.author} />
        </Typography>
        <Chip label="Dato rifiutato" color="error" variant="outlined" size="small" />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body2" sx={{ opacity: 0.9 }}>{`“${review.review.comment}”`}</Typography>
        {/* <SerniListUpdateStatusChip status={historyEntry.status.id} /> */}
      </Stack>
      <Typography component="div" variant="body2" width="100%">
        <Box component="ul" sx={{ padding: 0, width: '100%', '&, & span, & div': { fontSize: 'inherit' } }}>
          {reviewEquipment ? (
            <>
              <DetailPanelRow
                rowTitle="Equipment"
                direction="row"
                rowValue={reviewEquipment?.equipment}
                sx={{ padding: 0 }}
              />
              <DetailPanelRow
                rowTitle="Description"
                direction="row"
                rowValue={reviewEquipment?.equipmentDescription}
                sx={{ padding: 0 }}
              />
            </>
          ) : null}
          {reviewAttachment ? (
            <>
              <DetailPanelRow rowTitle="Nome" direction="row" rowValue={reviewAttachment.name} sx={{ padding: 0 }} />
            </>
          ) : null}
        </Box>
      </Typography>
    </BaseHistoryEntry>
  );
}

export const ReviewCommentHistoryEntry = React.memo(ReviewCommentHistoryEntryComponent);
