import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSliceReducer, commonDataApi } from '@top-solution/microtecnica-utils';
import { api } from '../services/baseApi';
import { procurementToolApi } from '../services/procurementToolApi';
import { publicApi } from '../services/publicApi';
import attachmentsStateReducer from './attachmentsStateSlice';
import countriesClassificationReducer from './countriesClassificationSlice';
import { attachmentListenerMiddleware } from './listeners/attachmentListenerMiddleware';
import { countriesClassificationMiddleware } from './listeners/countriesClassificationMiddleware';
import { materialListenerMiddleware } from './listeners/materialListenerMiddleware';
import { notificationsMiddleware } from './listeners/notificationsMiddleware';
import { paperworkCompletionMiddleware } from './listeners/paperworkCompletionMiddleware';
import { paperworkFieldValuesMiddleware } from './listeners/paperworkFieldValuesMiddleware';
import { paperworkNavigationMiddleware } from './listeners/paperworkNavigationMiddleware';
import { serniListUpdateCompletionMiddleware } from './listeners/serniListUpdateCompletionMiddleware';
import { stakeholderListenerMiddleware } from './listeners/stakeholderListenerMiddleware';
import materialsStateReducer from './materialsStateSlice';
import notificationsReducer from './notificationsSlice';
import paperworkFieldValuesStateReducer from './paperworkFieldValuesStateSlice';
import paperworkNavigationReducer from './paperworkNavigationSlice';
import stakeholdersStateReducer from './stakeholdersStateSlice';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    notifications: notificationsReducer,
    paperworkFieldValuesState: paperworkFieldValuesStateReducer,
    materialsState: materialsStateReducer,
    stakeholdersState: stakeholdersStateReducer,
    attachmentsState: attachmentsStateReducer,
    paperworkNavigation: paperworkNavigationReducer,
    countriesClassification: countriesClassificationReducer,
    [api.reducerPath]: api.reducer,
    [commonDataApi.reducerPath]: commonDataApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [procurementToolApi.reducerPath]: procurementToolApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      commonDataApi.middleware,
      publicApi.middleware,
      procurementToolApi.middleware,
      notificationsMiddleware.middleware,
      materialListenerMiddleware.middleware,
      stakeholderListenerMiddleware.middleware,
      attachmentListenerMiddleware.middleware,
      paperworkFieldValuesMiddleware.middleware,
      paperworkCompletionMiddleware.middleware,
      serniListUpdateCompletionMiddleware.middleware,
      paperworkNavigationMiddleware.middleware,
      countriesClassificationMiddleware.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
