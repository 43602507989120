import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { ContextField } from '../../../../../hooks/usePaperwork/usePaperworkFields';

export function useMaterialQuantityColumn(field: ContextField | undefined): GridColDef {
  const fieldDatatype = field?.datatype ?? PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY;
  return useMemo(
    () => ({
      headerName: field?.label ?? 'Quantità',
      type: 'number',
      width: 120,
      field: fieldDatatype,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[fieldDatatype]?.value);
        return Number.isNaN(v) ? null : v;
      },
    }),
    [field?.label, fieldDatatype]
  );
}
