import { useMemo } from 'react';
import { format } from 'date-fns/format';
import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import { Country, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { PaperworkRoute } from '../../../../entities/Paperwork';
import { Stakeholder, STAKEHOLDER_ROLE } from '../../../../entities/Stakeholder';
import {
  AttachmentsSections,
  DataEntryPages,
  OtherAttachmentsSubsections,
  PaperworkPhases,
} from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadEquipmentListQuery } from '../../../../services/equipmentiApi';
import { useReadFixturesQuery } from '../../../../services/preparationApi';

countries.registerLocale(it);

function formatModulesDate(date: string | Date) {
  return format(new Date(date), 'dd.MM.yyyy');
}

export function usePaperworkPreparationPatches(): Record<string, string | Array<Record<string, string>>> {
  const { paperwork } = usePaperwork();
  const { data: equipments } = useReadEquipmentListQuery();

  const { data: enabledCountries } = useReadCountriesQuery();

  const { data: microtecnicaStrings } = useReadFixturesQuery({ fixturesSet: 'microtecnica' });
  const { routes } = usePaperworkNavigation();

  const eusRoute =
    routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Eus];
  const visuraCameraleRoute =
    routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.VisuraCamerale];
  const qualifiedCompanyCertificateRoute =
    routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.OtherAttachments]
      .subsections[OtherAttachmentsSubsections.QualifiedCompanyCertificate];

  const patches: Record<string, string | Array<Record<string, string>>> = {};

  // ###### Microtecnica strings ################################################################################

  if (microtecnicaStrings) {
    for (const [key, value] of Object.entries(microtecnicaStrings)) {
      patches[`microtecnica_${key}`] = value;
    }
  }

  // ###### Microtecnica strings ################################################################################

  // ###### DEV / WIP / DEBUG ###################################################################################

  patches['protocollo_mt'] = `GT_${new Date().getFullYear()}/${Math.floor(Math.random() * 10)}`; //FIXME:
  patches['data_protocollo_mt'] = formatModulesDate(new Date()); //FIXME:

  // ###### DEV / WIP / DEBUG ###################################################################################

  const countriesByCode = useMemo(() => {
    const countriesByCode = new Map<string, Country>();
    for (const country of enabledCountries?.list ?? []) {
      countriesByCode.set(country.id, country);
    }
    return countriesByCode;
  }, [enabledCountries]);

  if (!paperwork) {
    return patches;
  }

  const paperworkCountry = countriesByCode.get(paperwork?.country ?? '');

  const stakeholdersById = new Map<string, Stakeholder>();
  if (paperwork.stakeholderList) {
    for (const stakeholder of paperwork.stakeholderList) {
      stakeholdersById.set(stakeholder.id, stakeholder);
    }
  }

  if (paperwork && paperworkCountry) {
    const createStakeholdersFixtures = (role: STAKEHOLDER_ROLE) => {
      if (!paperwork || !role) {
        return [];
      }
      return (paperwork.stakeholderList ?? [])
        ?.filter((stakeholder) => stakeholder.role === role)
        .map(({ imported: _imported, ...stakeholder }, index) => ({ ...stakeholder, index: `${index + 1}` }));
    };

    const createAttachmentsFixtures = (route: PaperworkRoute) => {
      const contextId = route.context?.id;
      if (!paperwork || !contextId) {
        return [];
      }
      return (paperwork.paperworkAttachmentList ?? [])
        ?.filter((attachment) => attachment.savedInContextId === contextId)
        .map((attachment, index) => {
          const firstStakeholder = attachment.stakeholderIdList?.[0]
            ? stakeholdersById.get(attachment.stakeholderIdList[0])
            : undefined;
          return {
            stakeholderCountry: firstStakeholder?.country ?? 'IT',
            stakeholderName: firstStakeholder?.name ?? 'Microtecnica',
            prettyDocumentType: route.title,
            index: `${index}`,
          };
        });
    };

    const createStakeholderCountriesFixtures = (role: STAKEHOLDER_ROLE) => {
      return createStakeholdersFixtures(role)
        .map((stakeholder) => countries.getName(stakeholder.country, 'it') ?? '')
        .join(', ');
    };

    patches['paperwork_id'] = `${paperwork.id}`;
    patches['paperwork_country_name'] = countries.getName(paperworkCountry.id, 'it') ?? '';
    patches['paperwork_st_end_users_countries'] = (paperwork.stakeholderList ?? [])
      .filter((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS)
      .map((stakeholder) => stakeholder.country)
      .join(' — ');
    patches['paperwork_st_end_users_names'] = (paperwork.stakeholderList ?? [])
      .filter((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS)
      .map((stakeholder) => stakeholder.name)
      .join(' — ');
    patches['paperwork_materials'] = (paperwork.materialList ?? [])?.map(({ imported: _, ...material }) => {
      const equipment = equipments?.bySerniCode[material.serniCode ?? ''];

      return {
        ...material,
        equipmentDescription: equipment?.description ?? '',
        fullSerniCode: equipment
          ? // TODO: utils
            `${equipment.equipmentType}.${equipment.category}.${equipment.paragraph}.${equipment.subParagraph}.${equipment.serniCode}`
          : '',
      };
    });
    patches['paperwork_stakeholders_ship_to'] = createStakeholdersFixtures(STAKEHOLDER_ROLE.SHIP_TO);
    patches['paperwork_stakeholders_bill_to'] = createStakeholdersFixtures(STAKEHOLDER_ROLE.BILL_TO);
    patches['paperwork_stakeholders_order_from'] = createStakeholdersFixtures(STAKEHOLDER_ROLE.ORDER_FROM);
    patches['paperwork_stakeholders_end_users'] = createStakeholdersFixtures(STAKEHOLDER_ROLE.END_USERS);
    patches['paperwork_stakeholders_ship_to_countries'] = createStakeholderCountriesFixtures(STAKEHOLDER_ROLE.SHIP_TO);
    patches['paperwork_stakeholders_bill_to_countries'] = createStakeholderCountriesFixtures(STAKEHOLDER_ROLE.BILL_TO);
    patches['paperwork_stakeholders_order_from_countries'] = createStakeholderCountriesFixtures(
      STAKEHOLDER_ROLE.ORDER_FROM
    );
    patches['paperwork_stakeholders_end_users_countries'] = createStakeholderCountriesFixtures(
      STAKEHOLDER_ROLE.END_USERS
    );
    patches['paperwork_attachments_eus'] = createAttachmentsFixtures(eusRoute);
    patches['paperwork_attachments_visura_camerale'] = createAttachmentsFixtures(visuraCameraleRoute);
    patches['paperwork_attachments_qualified_company_certificate'] = createAttachmentsFixtures(
      qualifiedCompanyCertificateRoute
    );
    patches['paperwork_attachments_visura_camerale_qualified_company_certificate'] = [
      ...patches['paperwork_attachments_visura_camerale'],
      ...patches['paperwork_attachments_qualified_company_certificate'],
    ];
  }

  return patches;
}
