import { createListenerMiddleware, ListenerEffectAPI, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import {
  Paperwork,
  PAPERWORK_FIELD_DATATYPE,
  PAPERWORK_MOVEMENT,
  PAPERWORK_TYPE,
  PaperworkContext,
  PaperworkRoute,
  PaperworkRouteConfiguration,
} from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import {
  AttachmentsSections,
  DataEntryPages,
  OrderSubsections,
  OtherAttachmentsSubsections,
  PaperworkPhases,
  PaperworksNavigationStructure,
  PreparationSigningPages,
} from '../../hooks/usePaperwork/paperworkNavigation';
import { paperworkRoutesConfiguration } from '../../hooks/usePaperwork/paperworkRouteConfiguration';
import { paperworkSection } from '../../pages/Paperwork/sections';
import { paperworksApi } from '../../services/paperwork';
import { selectCountriesClassification } from '../countriesClassificationSlice';
import { paperworkNavigationSlice, setPaperworkNavigation } from '../paperworkNavigationSlice';
import { store } from '../store';

export const paperworkNavigationMiddleware = createListenerMiddleware();

/**
 * Find the matching PaperworkContext given an array of PaperworkContext.
 * Its generic ancestor contexts will match.
 */
function findContext(
  contexts: PaperworkContext[] | undefined,
  phase: string,
  page?: string,
  section?: string,
  subsection?: string
): PaperworkContext | undefined {
  return contexts?.find(
    (context) =>
      context.phase === phase &&
      (page === undefined || context.page === page) &&
      (section === undefined || context.section === section) &&
      (subsection === undefined || context.subsection === subsection)
  );
}

/**
 * Find the exact matching PaperworkContext given an array of PaperworkContext.
 */
export function findStrictContext(
  contexts: PaperworkContext[] | undefined,
  phase: string,
  page?: string,
  section?: string,
  subsection?: string
): PaperworkContext | undefined {
  return contexts?.find(
    (context) =>
      context.phase === phase &&
      context.page === page &&
      context.section === section &&
      context.subsection === subsection
  );
}

/**
 * Applies FE exceptions to the (current) paperwork template coming from API.
 */
function applyPaperworkRouteExceptions(
  paperworkId: Paperwork['id'],
  state: ReturnType<typeof store.getState>,
  routes: PaperworksNavigationStructure<PaperworkRoute>
) {
  const { data: paperwork } = paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state);
  const { data: templates } = paperworksApi.endpoints.readPaperworkTemplatesList.select()(state);
  const { data: paperworkValues } = paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state);
  const { countries } = selectCountriesClassification(state);

  if (!paperwork || !templates || !countries) {
    return;
  }

  const paperworkType = paperwork.paperworkTemplate.paperworkType.name;
  const paperworkMovement = paperwork.paperworkTemplate.movementType?.name;

  const shipToStakeholder = paperwork.stakeholderList?.find(
    (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
  );

  const endUserStakeholder =
    paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS) ??
    paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO);

  const shipToCountry = shipToStakeholder && countries[shipToStakeholder.country];

  const otherAttachmentsSubsections =
    routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.OtherAttachments]
      .subsections ?? {};

  const attachmentSections = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections ?? {};

  const orderSubsections =
    routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Order]
      .subsections ?? {};

  const endUserMatchesValue = paperworkValues?.find(
    (value) => value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
  );

  const endUserCountry =
    endUserMatchesValue?.value === 'microtecnica'
      ? countries['IT']
      : endUserMatchesValue?.value === 'ship-to'
        ? shipToCountry
        : endUserStakeholder && countries[endUserStakeholder.country];

  const endUserIsMilitaryBase =
    paperworkValues?.find(
      (value) =>
        endUserStakeholder &&
        value.stakeholderId === endUserStakeholder.id &&
        value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE
    )?.value === 'true';

  function disableAllAttachments() {
    for (const [_, section] of Object.entries(attachmentSections)) {
      section.enabled = false;

      for (const [_, subsection] of Object.entries(section.subsections ?? {})) {
        subsection.enabled = false;
      }
    }
  }

  // #### Matrice 8 ############################################################
  if (paperworkType === PAPERWORK_TYPE.IndividualeTangibile || paperworkType === PAPERWORK_TYPE.LicenzaFramework) {
    // Let's ignore completely the template from database and override it with absurd front-end logic
    disableAllAttachments();

    if (shipToCountry && endUserCountry) {
      const isProgramEftr = paperwork.programList?.length === 0 && paperwork.programList[0] === 'EFTR';

      if (isProgramEftr) {
        //  se il programma è EFA (paese utilizzatore finale IT - DE - ES - UK - AT) non sono necessari documenti, il tool
        //    non proporrà nessun documento da caricare nella richiesta
        routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].active = false;
      } else {
        if (paperworkType === PAPERWORK_TYPE.IndividualeTangibile) {
          // L. 185 - Licenza individuale - Esportazione definitiva

          if (paperworkMovement === PAPERWORK_MOVEMENT.ImportazioneDefinitiva) {
            // nessun documento per il momento	il tool deve segnalare all'utente di verificare con GT eventuali documenti da presentare
            // #239 :  Ma se l'utente effettivamente riscontra con GT che deve fornire un documento? In questo caso, da specifiche non sono previsti documenti, quindi non può aggiungerlo
            //         ho pensato la stessa cosa, poiché la collega non ha detto nulla immaginavo li aggiungesse lei nella pec se si può aggiungere una sezione senza perdere troppo tempo sì
            attachmentSections[AttachmentsSections.OtherAttachments].enabled = true;
            otherAttachmentsSubsections[OtherAttachmentsSubsections.OtherAttachments].enabled = true;
          }

          if (paperworkMovement === PAPERWORK_MOVEMENT.EsportazioneDefinitiva) {
            if (shipToCountry.isNato && (endUserCountry.isNato || endUserCountry.isExtraNato)) {
              // Paese destinatario NATO / End User Nato o Extra Nato
              // Ordine - è obbligatorio che ce ne sia almeno uno
              // Accettazione d'ordine - obbligatorio
              // EUS - obbligatorio per end user Nato
              // IIC - obbligatorio per end user Nato
              // EUC - solo per Eud User Extra Nato

              attachmentSections[AttachmentsSections.Order].enabled = true;
              orderSubsections[OrderSubsections.Order].enabled = true;
              orderSubsections[OrderSubsections.OrderApproval].enabled = true;

              if (endUserCountry.isNato) {
                attachmentSections[AttachmentsSections.Eus].enabled = true;
                attachmentSections[AttachmentsSections.OtherAttachments].enabled = true;
                otherAttachmentsSubsections[OtherAttachmentsSubsections.Iic].enabled = true;
              }
              if (endUserCountry.isExtraNato) {
                attachmentSections[AttachmentsSections.Euc].enabled = true;
              }
            }

            if (shipToCountry.isExtraNato && endUserIsMilitaryBase) {
              // Paese destinatario Extra NATO - End User = Forza Armata
              // Ordine - è obbligatorio che ce ne sia almeno uno
              // Accettazione d'ordine - obbligatorio
              // EUC - obbligatorio
              // ~IIC~ - alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 2 // TODO:
              // ~Certificato di ditta abilitata~- l'utente deve obbligatoriamente caricarne almeno uno dei 2

              attachmentSections[AttachmentsSections.Order].enabled = true;
              orderSubsections[OrderSubsections.Order].enabled = true;
              orderSubsections[OrderSubsections.OrderApproval].enabled = true;
              attachmentSections[AttachmentsSections.OtherAttachments].enabled = true;
              attachmentSections[AttachmentsSections.Euc].enabled = true;
              // 👇 these were crossed out on the Matrice 8 specs
              // otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = true;
              // otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = true;
            }

            if (shipToCountry.isExtraNato && !endUserIsMilitaryBase) {
              // Paese destinatario Extra NATO / End User azienda (non Forza Armata) Extra Nato
              // Ordine - è obbligatorio che ce ne sia almeno uno
              // Accettazione d'ordine - obbligatorio
              // EUC - facoltativi, almeno uno deve essere caricato
              // EUS - facoltativi, almeno uno deve essere caricato
              // IIC - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
              // Certificato di ditta abilitata - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
              // Copia di una licenza valida di esportazione - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )

              attachmentSections[AttachmentsSections.Order].enabled = true;
              attachmentSections[AttachmentsSections.Euc].enabled = true;
              attachmentSections[AttachmentsSections.Eus].enabled = true;
              attachmentSections[AttachmentsSections.OtherAttachments].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.Iic].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.QualifiedCompanyCertificate].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.CopyOfAValidExportLicence].enabled = true;
            }
          }

          if (paperworkMovement === PAPERWORK_MOVEMENT.TemporaneaImportazioneESuccessivaRiesportazione) {
            // Paese destinatario ed end user NATO / Extra Nato
            // Ordine - è obbligatorio che ce ne sia almeno uno
            // Accettazione d'ordine - obbligatorio
            // ~EUS~ - obbligatorio
            if (
              (shipToCountry.isNato || shipToCountry.isExtraNato) &&
              (endUserCountry.isNato || endUserCountry.isExtraNato)
            ) {
              attachmentSections[AttachmentsSections.Order].enabled = true;
              orderSubsections[OrderSubsections.Order].enabled = true;
              orderSubsections[OrderSubsections.OrderApproval].enabled = true;
              // 👇 this was crossed out on the Matrice 8 specs
              // attachmentSections[AttachmentsSections.Eus].enabled = true;
            }
          }

          if (paperworkMovement === PAPERWORK_MOVEMENT.TemporaneaEsportazioneESuccessivaReimportazione) {
            // Paese destinatario ed end user NATO / Extra Nato
            // Ordine - è obbligatorio che ce ne sia almeno uno
            // Accettazione d'ordine - obbligatorio
            // EUS - obbligatorio
            // IIC - alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 3 // TODO:
            // Certificato di ditta abilitata - l'utente deve obbligatoriamente caricarne almeno uno dei 3
            // Copia di una licenza valida di esportazione - l'utente deve obbligatoriamente caricarne almeno uno dei 3
            if (
              (shipToCountry.isNato || shipToCountry.isExtraNato) &&
              (endUserCountry.isNato || endUserCountry.isExtraNato)
            ) {
              attachmentSections[AttachmentsSections.Order].enabled = true;
              orderSubsections[OrderSubsections.Order].enabled = true;
              orderSubsections[OrderSubsections.OrderApproval].enabled = true;
              attachmentSections[AttachmentsSections.Eus].enabled = true;
              attachmentSections[AttachmentsSections.OtherAttachments].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.Iic].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.QualifiedCompanyCertificate].enabled = true;
              otherAttachmentsSubsections[OtherAttachmentsSubsections.CopyOfAValidExportLicence].enabled = true;
            }
          }
        }

        if (paperworkType === PAPERWORK_TYPE.LicenzaFramework) {
          //L. 185 - Licenza Framework
          // Framework - è obbligatorio che ce ne sia almeno uno
          attachmentSections[AttachmentsSections.Framework].enabled = true;
        }
      }
    }

    // #### Matrice 8 ############################################################
  }

  if (paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Intangibile') {
    // #### Matrice 16 ###########################################################

    if (shipToCountry?.isExtraNato) {
      otherAttachmentsSubsections[OtherAttachmentsSubsections.QualifiedCompanyCertificate].enabled = false;
    }

    // #### Matrice 16 ###########################################################
  }

  // #### Other Matrice-unrelated stuff ##########################################

  // // Do not ask for End User Statements if the end user is Microtecnica itself
  // Removed because https://github.com/top-solution/microtecnica.ilcm/issues/238
  // if (endUserMatchesValue && endUserMatchesValue.value === 'microtecnica') {
  //   if (paperworkValues) {
  //     routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Eus].enabled =
  //       false;
  //   }
  // }
  // #### Other Matrice-unrelated stuff ##########################################

  // #### FIXME: DEBUG #########################################################
  routes[PaperworkPhases.PreparationSigning].enabled = true;
  routes[PaperworkPhases.PreparationSigning].active = true;
  // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].enabled = true;
  // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].active = true;
  routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].enabled = true;
  routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].active = true;
  // #### FIXME: DEBUG #########################################################
}

async function generatePaperworkNavigation(
  paperworkId: Paperwork['id'],
  state: ReturnType<typeof store.getState>,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>
) {
  const { data: paperwork } = await paperworksApi.endpoints.readPaperwork.select({ paperworkId })(state);
  const { data: templates } = await paperworksApi.endpoints.readPaperworkTemplatesList.select()(state);
  // const { data: values } = await paperworksApi.endpoints.readPaperworkValues.select({ paperworkId })(state);

  if (!paperwork || !templates) {
    return;
  }

  // The path of the Paperwork Dashboard page
  const dashboardPagePath = [`${paperworkSection.path}`, paperwork?.id].join('/');

  // The NavigationContext of the specific route the browser is currently on
  const currentNavigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'> = {
    phase: PaperworkPhases.Configuration,
  };

  let contexts = undefined;
  const template = templates.find((template) => {
    return template.id === paperwork.paperworkTemplate.id;
  });
  contexts = template?.paperworkContextList ?? [];

  const currentRoute: PaperworkRoute = {
    path: '',
    absolutePath: '',
    title: '',
    matcher: '',
    absoluteMatcher: '',
    active: false,
    enabled: false,
  };

  const routeByContextId: Record<number, PaperworkRoute> = {};

  /**
   * Semi-magic function that takes a list of paperworkRoutesConfiguration branches and generates a
   * PaperworkRoute object out of it.
   * The function is called recursively on the route sub-routes (i.e. for a page, is recursively called on
   * its sections)
   * @param routeConfiguration a paperworkRoutesConfiguration branch object
   * @param depth the recursion depth level
   * @param parentPath the parent route path
   * @param parentNavigationContext the parent route navigation context
   * @returns a PaperworkRoute object.
   */
  const generateRoute = (
    routeConfiguration: Record<string, PaperworkRouteConfiguration>,
    depth: number,
    parentPath: string[],
    parentNavigationContext: Pick<PaperworkContext, 'phase' | 'page' | 'section' | 'subsection'>
  ) => {
    const route: Record<string, PaperworkRoute> = {};

    // Iterate all configurations (i.e. all the pages inside a phase)
    for (const [key, value] of Object.entries(routeConfiguration)) {
      const absolutePath = [...parentPath, key].join('/');
      const matcher = `${key}/*`;
      const absoluteMatcher = `${paperworkSection.path}/:id/${absolutePath}/*`;
      const title = paperwork ? value.title(paperwork) : '';

      // Find out if the name of this route is actually a phase, a page, etc...
      // and merge that info with the parent context — in this way the context
      // is recursively built
      const navigationContext = { ...parentNavigationContext };
      if (depth === 0) {
        navigationContext.phase = key;
      }
      if (depth === 1) {
        navigationContext.page = key;
      }
      if (depth === 2) {
        navigationContext.section = key;
      }
      if (depth === 3) {
        navigationContext.subsection = key;
      }
      const paperworkRoute: PaperworkRoute = {
        ...value,
        path: key,
        absolutePath,
        matcher,
        absoluteMatcher,
        active: false,
        enabled: false,
        title,
        icon: value.icon,
      };

      // Generate child routes
      // Sadly, I couldn't find a way to nicely have value strongly typed
      if ('pages' in value) {
        paperworkRoute['pages'] = generateRoute(
          value.pages as Record<string, PaperworkRouteConfiguration>,
          depth + 1,
          [...parentPath, key],
          navigationContext
        );
      }
      if ('sections' in value) {
        paperworkRoute['sections'] = generateRoute(
          value.sections as Record<string, PaperworkRouteConfiguration>,
          depth + 1,
          [...parentPath, key],
          navigationContext
        );
      }
      if ('subsections' in value) {
        paperworkRoute['subsections'] = generateRoute(
          value.subsections as Record<string, PaperworkRouteConfiguration>,
          depth + 1,
          [...parentPath, key],
          navigationContext
        );
      }

      // Find the PaperworkContext matching the navigation context
      const context =
        findStrictContext(
          contexts,
          navigationContext.phase,
          navigationContext.page,
          navigationContext.section,
          navigationContext.subsection
        ) ??
        findContext(
          contexts,
          navigationContext.phase,
          navigationContext.page,
          navigationContext.section,
          navigationContext.subsection
        );
      paperworkRoute.context = context;

      if (paperwork && context) {
        paperworkRoute.active = Boolean(context);
        paperworkRoute.enabled = paperworkRoute.active && paperwork?.status.id >= context?.availableFromStatus.id;
      }

      route[key] = paperworkRoute;

      if (context) {
        routeByContextId[context.id] = paperworkRoute;
      }
    }

    return route;
  };

  const phasesRoutes = generateRoute(paperworkRoutesConfiguration, 0, [], {
    phase: '',
  }) as PaperworksNavigationStructure<PaperworkRoute>;

  if (paperwork) {
    applyPaperworkRouteExceptions(paperworkId, state, phasesRoutes);
  }

  const paperworkNavigation = {
    paperworkId,
    /**
     * A PaperworksNavigationStructure-like object describing the routes of /paperwork
     */
    routes: phasesRoutes,
    /**
     * The NavigationContext related to the route the browser is currently on
     */
    navigationContext: currentNavigationContext,
    /**
     * The path of the Paperwork Details page
     */
    detailsPath: dashboardPagePath,
    /**
     * The the route the browser is currently on
     */
    route: currentRoute as unknown as PaperworkRoute | undefined,
    /**
     * Get a route in exchange for a context ID
     */
    routeByContextId,
  };

  listenerApi.dispatch(setPaperworkNavigation(paperworkNavigation));
}

paperworkNavigationMiddleware.startListening({
  actionCreator: paperworkNavigationSlice.actions.setPaperworkId,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as ReturnType<typeof store.getState>;

    const paperworkId = action.payload.paperworkId;

    generatePaperworkNavigation(paperworkId, state, listenerApi);
  },
});
