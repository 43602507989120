import { PaperworkRoute } from '../../entities/Paperwork';

export enum PaperworkPhases {
  Configuration = 'configuration',
  DataEntry = 'data-entry',
  PreparationSigning = 'preparation-signing',
  IssuingDeduction = 'issuing-deduction',
}

export enum DataEntryPages {
  Materials = 'materials',
  Stakeholders = 'stakeholders',
  Attachments = 'attachments',
  ContractualTerms = 'contractual-terms',
  AdditionalEconomicConditions = 'additional-economic-conditions',
  PaperworkTransaction = 'paperwork-transaction',
  TotalQuantity = 'total-quantity',
  MaterialKeepHyphen = 'material-keep-hyphen',
  Extension = 'extension',
  Closure = 'closure',
}

export enum MaterialsSections {
  BillingYears = 'material-billing-years',
  SelectPartNumbers = 'select-part-numbers',
  SelectPartNumbersWithoutEquipment = 'select-part-numbers-without-equipment',
  SelectPartNumbersWithoutPn = 'select-part-numbers-without-pn',
  MaterialsData = 'materials-data',
  AdditionalEconomicConditions = 'additional-economic-conditions',
  PaperworkTransaction = 'paperwork-transaction',
  TotalQuantity = 'total-quantity',
  MaterialKeepHyphen = 'material-keep-hyphen',
}

export enum SelectPartNumbersSubsections {
  MaterialsData = 'materials-data',
}

export enum StakeholdersSections {
  ShipTo = 'ship-to',
  BillTo = 'bill-to',
  OrderFrom = 'order-from',
  EndUser = 'end-user',
  Buyer = 'buyer',
}

export enum BillToSubsections {
  SkipStakeholdersInputPanel = 'skip-stakeholders-input-panel',
}

export enum EndUsersSubsections {
  SkipStakeholdersInputPanel = 'skip-stakeholders-input-panel',
}

export enum AttachmentsSections {
  Order = 'order',
  Eus = 'eus-statement',
  Euc = 'euc',
  VisuraCamerale = 'visura-camerale',
  OtherAttachments = 'other-attachments',
  Framework = 'framework',
  Nda = 'nda',
}

export enum OrderSubsections {
  Order = 'order',
  OrderApproval = 'order-approval',
}

export enum OtherAttachmentsSubsections {
  LoiVendor = 'loi-vendor',
  LoiCustomer = 'loi-customer',
  VendorLicence = 'vendor-licence',
  QualifiedCompanyCertificate = 'qualified-company-certificate',
  CopyOfAValidExportLicence = 'copy-of-a-valid-export-licence',
  Iic = 'iic',
  OrderDetails = 'order-details',
  OrderVendor = 'order-vendor',
  OrderCustomer = 'order-customer',
  OtherAttachments = 'other-attachments',
  SupportingDocuments = 'supporting-docs',
  EveryLicenceAvailableBill = 'every-licence-available-bill',
  EveryBillOrInvoice = 'every-bill-or-invoice',
  CopyInvoices = 'copy-invoices',
}

export enum PreparationSigningPages {
  Preparation = 'preparation',
  Signing = 'signing',
}

export enum SigningSection {
  Attachments = 'attachments',
}

export enum IssuingDeductionPages {
  Issuing = 'issuing',
  Deduction = 'deduction', // FIXME: the worst translation in this file.
}

export type PaperworksNavigationStructure<T> = {
  [PaperworkPhases.Configuration]: T;
  [PaperworkPhases.DataEntry]: T & {
    pages: {
      [DataEntryPages.Materials]: T & {
        sections: {
          [MaterialsSections.BillingYears]: T;
          [MaterialsSections.SelectPartNumbers]: T;
          [MaterialsSections.SelectPartNumbersWithoutEquipment]: T;
          [MaterialsSections.SelectPartNumbersWithoutPn]: T;
          [MaterialsSections.MaterialsData]: T;
          [MaterialsSections.AdditionalEconomicConditions]: T;
          [MaterialsSections.PaperworkTransaction]: T;
          [MaterialsSections.TotalQuantity]: T;
          [MaterialsSections.MaterialKeepHyphen]: T;
        };
      };
      [DataEntryPages.Stakeholders]: T & {
        sections: {
          [StakeholdersSections.OrderFrom]: T;
          [StakeholdersSections.ShipTo]: T;
          [StakeholdersSections.Buyer]: T;
          [StakeholdersSections.BillTo]: T & {
            subsections: {
              [BillToSubsections.SkipStakeholdersInputPanel]: T;
            };
          };
          [StakeholdersSections.EndUser]: T & {
            subsections: {
              [EndUsersSubsections.SkipStakeholdersInputPanel]: T;
            };
          };
        };
      };
      [DataEntryPages.Attachments]: T & {
        sections: {
          [AttachmentsSections.Order]: T & {
            subsections: {
              [OrderSubsections.Order]: T;
              [OrderSubsections.OrderApproval]: T;
            };
          };
          [AttachmentsSections.Euc]: T;
          [AttachmentsSections.Eus]: T;
          [AttachmentsSections.Framework]: T;
          [AttachmentsSections.Nda]: T;
          [AttachmentsSections.VisuraCamerale]: T;
          [AttachmentsSections.OtherAttachments]: T & {
            subsections: {
              [OtherAttachmentsSubsections.LoiVendor]: T;
              [OtherAttachmentsSubsections.LoiCustomer]: T;
              [OtherAttachmentsSubsections.OrderVendor]: T;
              [OtherAttachmentsSubsections.OrderCustomer]: T;
              [OtherAttachmentsSubsections.VendorLicence]: T;
              [OtherAttachmentsSubsections.QualifiedCompanyCertificate]: T;
              [OtherAttachmentsSubsections.CopyOfAValidExportLicence]: T;
              [OtherAttachmentsSubsections.SupportingDocuments]: T;
              [OtherAttachmentsSubsections.EveryLicenceAvailableBill]: T;
              [OtherAttachmentsSubsections.EveryBillOrInvoice]: T;
              [OtherAttachmentsSubsections.CopyInvoices]: T;
              [OtherAttachmentsSubsections.Iic]: T;
              [OtherAttachmentsSubsections.OrderDetails]: T;
              [OtherAttachmentsSubsections.OtherAttachments]: T;
            };
          };
        };
      };
      [DataEntryPages.ContractualTerms]: T;
      [DataEntryPages.Extension]: T;
      [DataEntryPages.Closure]: T;
    };
  };
  [PaperworkPhases.PreparationSigning]: T & {
    pages: {
      [PreparationSigningPages.Preparation]: T;
      [PreparationSigningPages.Signing]: T & {
        sections: {
          [SigningSection.Attachments]: T;
        };
      };
    };
  };
  [PaperworkPhases.IssuingDeduction]: T & {
    pages: {
      [IssuingDeductionPages.Issuing]: T;
      [IssuingDeductionPages.Deduction]: T;
    };
  };
};

const emptyPaperworkRoute: PaperworkRoute = {
  path: '',
  absolutePath: '',
  title: '',
  matcher: '',
  absoluteMatcher: '',
  active: false,
  enabled: false,
};

export const emptyPaperworkNavigation: PaperworksNavigationStructure<PaperworkRoute> = {
  [PaperworkPhases.Configuration]: { ...emptyPaperworkRoute },
  [PaperworkPhases.DataEntry]: {
    ...emptyPaperworkRoute,
    pages: {
      [DataEntryPages.Materials]: {
        ...emptyPaperworkRoute,
        sections: {
          [MaterialsSections.BillingYears]: { ...emptyPaperworkRoute },
          [MaterialsSections.SelectPartNumbers]: { ...emptyPaperworkRoute },
          [MaterialsSections.SelectPartNumbersWithoutEquipment]: { ...emptyPaperworkRoute },
          [MaterialsSections.SelectPartNumbersWithoutPn]: { ...emptyPaperworkRoute },
          [MaterialsSections.MaterialsData]: { ...emptyPaperworkRoute },
          [MaterialsSections.AdditionalEconomicConditions]: { ...emptyPaperworkRoute },
          [MaterialsSections.PaperworkTransaction]: { ...emptyPaperworkRoute },
          [MaterialsSections.TotalQuantity]: { ...emptyPaperworkRoute },
          [MaterialsSections.MaterialKeepHyphen]: { ...emptyPaperworkRoute },
        },
      },
      [DataEntryPages.Stakeholders]: {
        ...emptyPaperworkRoute,
        sections: {
          [StakeholdersSections.OrderFrom]: { ...emptyPaperworkRoute },
          [StakeholdersSections.ShipTo]: { ...emptyPaperworkRoute },
          [StakeholdersSections.Buyer]: { ...emptyPaperworkRoute },
          [StakeholdersSections.BillTo]: {
            ...emptyPaperworkRoute,
            subsections: {
              [BillToSubsections.SkipStakeholdersInputPanel]: { ...emptyPaperworkRoute },
            },
          },
          [StakeholdersSections.EndUser]: {
            ...emptyPaperworkRoute,
            subsections: {
              [EndUsersSubsections.SkipStakeholdersInputPanel]: { ...emptyPaperworkRoute },
            },
          },
        },
      },
      [DataEntryPages.Attachments]: {
        ...emptyPaperworkRoute,
        sections: {
          [AttachmentsSections.Order]: {
            ...emptyPaperworkRoute,
            subsections: {
              [OrderSubsections.Order]: { ...emptyPaperworkRoute },
              [OrderSubsections.OrderApproval]: { ...emptyPaperworkRoute },
            },
          },
          [AttachmentsSections.Euc]: { ...emptyPaperworkRoute },
          [AttachmentsSections.Eus]: { ...emptyPaperworkRoute },
          [AttachmentsSections.Framework]: { ...emptyPaperworkRoute },
          [AttachmentsSections.Nda]: { ...emptyPaperworkRoute },
          [AttachmentsSections.VisuraCamerale]: { ...emptyPaperworkRoute },
          [AttachmentsSections.OtherAttachments]: {
            ...emptyPaperworkRoute,
            subsections: {
              [OtherAttachmentsSubsections.LoiVendor]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.LoiCustomer]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.OrderVendor]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.OrderCustomer]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.VendorLicence]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.QualifiedCompanyCertificate]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.CopyOfAValidExportLicence]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.SupportingDocuments]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.EveryLicenceAvailableBill]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.EveryBillOrInvoice]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.CopyInvoices]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.Iic]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.OrderDetails]: { ...emptyPaperworkRoute },
              [OtherAttachmentsSubsections.OtherAttachments]: { ...emptyPaperworkRoute },
            },
          },
        },
      },
      [DataEntryPages.ContractualTerms]: { ...emptyPaperworkRoute },
      [DataEntryPages.Extension]: { ...emptyPaperworkRoute },
      [DataEntryPages.Closure]: { ...emptyPaperworkRoute },
    },
  },
  [PaperworkPhases.PreparationSigning]: {
    ...emptyPaperworkRoute,
    pages: {
      [PreparationSigningPages.Preparation]: {
        ...emptyPaperworkRoute,
      },
      [PreparationSigningPages.Signing]: {
        ...emptyPaperworkRoute,
        sections: {
          [SigningSection.Attachments]: { ...emptyPaperworkRoute },
        },
      },
    },
  },
  [PaperworkPhases.IssuingDeduction]: {
    ...emptyPaperworkRoute,
    pages: {
      [IssuingDeductionPages.Issuing]: { ...emptyPaperworkRoute },
      [IssuingDeductionPages.Deduction]: { ...emptyPaperworkRoute },
    },
  },
};
